<div class="action-container d-flex">
    <img src="../../assets/images/Who.svg" class="img-fluid" (click)="open(modalWho)">
    <button data-toggle="modal" class="bg-primary btn btn-primary btn-lg align-self-center" (click)="open(modalWho)">Who?</button>
</div>
<ng-template #modalWho let-modal>
    <div class="modal-header">
        <h2 class="modal-title" id="modal-basic-title">PROVOCATEUR</h2>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
    </div>
    <div class="modal-body">
        <div class="mb-5">
            <h5>SUMMARY</h5>
            <p>
                Note that whilst various individuals/accounts have been involved in the circulation of mobilisation sentiments, the following individuals have been identified as active provocateurs (noting that no violent rhetoric has been noted).
            </p>
        </div>
        <div *ngFor="let topic of topics">
            <h3 class="text-center mt-3 mb-3">{{topic.name}}</h3>
            <div *ngIf="topic.topInfluencer" class="">
                <h4><i>Top Influencer</i></h4>
                <p>{{topic.topInfluencer.Labels}} profile available at <a href="{{topic.topInfluencer.Profile}}">{{topic.topInfluencer.Profile}}</a>, has been identified as the most active provocateur, and has the largest reach and engagement on Twitter.</p>
            </div>
            <table class="table table-hover">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Influencer</th>
                        <th>Posts</th>
                        <th>Reach</th>
                        <th>Reach per mention</th>
                        <th>Engagement</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let influencer of topic.topTenInfluencers; index as count" [ngClass]="{'highlighted': topic.topInfluencer.Labels == influencer.Labels}">
                        <td>{{ count + 1 }}</td>
                        <td><img class="rounded-circle" src="{{influencer.Avatar}}" alt="twitter avatar"> {{influencer.Labels}}</td>
                        <td>{{influencer.Total}}</td>
                        <td>{{influencer.Reach}}</td>
                        <td>{{influencer["Reach per Result"]}}</td>
                        <td>{{influencer.Engagement}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
    </div>
</ng-template>