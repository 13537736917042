import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
    formData: any = {};
    loginResult: any;

    constructor(
        private router: Router,
        private apiService: ApiService
    ) { }

    ngOnInit(): void {
    }

    login(): void {
        this.apiService.login({
            email: this.formData.email,
            password: this.formData.password
        }).subscribe((resultData: any) => {
            this.loginResult = resultData;
            if (this.loginResult.success) {
                localStorage.setItem('id', this.loginResult.id);
                localStorage.setItem('jwt', this.loginResult.jwt);
                this.router.navigate(['']);
            }
        });
    }

}
