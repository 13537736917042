import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-risk-summary',
  templateUrl: './risk-summary.component.html',
  styleUrls: ['./risk-summary.component.css']
})
export class RiskSummaryComponent implements OnInit {
    @Input() riskSummary: any;

  constructor() { }

  ngOnInit(): void {
  }

}
