import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { ProfileService } from 'src/app/services/profile.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-existing-profiles-individual',
    templateUrl: './existing-profiles-individual.component.html',
    styleUrls: ['./existing-profiles-individual.component.css']
})
export class ExistingProfilesIndividualComponent implements OnInit {

    constructor(
        private apiService: ApiService,
        private profileService: ProfileService,
        private router: Router
    ) { }

    profiles: Array<any> = [];

    ngOnInit(): void {
        this.getProfileList();
    }

    getProfileList(): void {
        this.apiService.getProfileList('individual').subscribe((resultData: any) => {
            if (resultData.success) {
                this.profiles = resultData.data;
            }
        });

    }

    select(profileId: number): void {
        this.profileService.id = profileId;
        this.router.navigate(['/profile-edit']);
    }
}
