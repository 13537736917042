<header class="p-3">
    <h1 class="bg-white p-4 text-center rounded-lg shadow">Profiles - Edit profile</h1>
</header>
<div *ngIf="consumerDetails" class="container p-3">
    <nav class="bg-white p-3 m-3 rounded-lg shadow d-flex justify-content-between">
        <app-back></app-back>
        <app-logout></app-logout>
    </nav>
    <div class="form-group bg-white p-3 m-3 rounded-lg shadow">
        <h2 class="text-center">{{consumerDetails.FirstName}} {{consumerDetails.Surname}} - {{consumerDetails.IDNo}}</h2>
    </div>
    <form>
        <div class="form-group bg-white p-3 m-3 rounded-lg shadow">
            <h3>Background Information</h3>
            <div *ngIf="!backgroundInformation.content">
                <button class="btn btn-primary" (click)="addContent(backgroundInformation, 'Background Information', parentId)">Add content</button>
            </div>
            <div *ngIf="backgroundInformation.content">
                <button class="btn btn-sm btn-primary" (click)="editContent(backgroundInformation.dataId, $event)">Edit</button>
                <div [innerHTML]="backgroundInformation.content"></div>
            </div>
        </div>
        <div class="form-group bg-white p-3 m-3 rounded-lg shadow">
            <h3>Personal Details Summary</h3>
            <table class="table table-hover">
                <tbody>
                    <tr>
                        <th>ID No:</th>
                        <td>{{consumerDetails.IDNo}}</td>
                        <th>Passport Or 2nd ID No:</th>
                        <td>{{consumerDetails.PassportNo}}</td>
                    </tr>
                    <tr>
                        <th>Surname:</th>
                        <td>{{consumerDetails.Surname}}</td>
                        <th>Residential Address:</th>
                        <td>{{consumerDetails.ResidentialAddress}}</td>
                    </tr>
                    <tr>
                        <th>First Name:</th>
                        <td>{{consumerDetails.FirstName}}</td>
                        <th>Postal Address:</th>
                        <td>{{consumerDetails.ResidentialAddress}}</td>
                    </tr>
                    <tr>
                        <th>Second Name:</th>
                        <td>{{consumerDetails.SecondName}}</td>
                        <th>Telephone No. (H):</th>
                        <td>{{consumerDetails.HomeTelephoneNo}}</td>
                    </tr>
                    <tr>
                        <th>Title:</th>
                        <td>{{consumerDetails.TitleDesc}}</td>
                        <th>Telephone No. (W):</th>
                        <td>{{consumerDetails.WorkTelephoneNo}}</td>
                    </tr>
                    <tr>
                        <th>Gender:</th>
                        <td>{{consumerDetails.Gender}}</td>
                        <th>Cellular/Mobile:</th>
                        <td>{{consumerDetails.CellularNo}}</td>
                    </tr>
                    <tr>
                        <th>Date of Birth:</th>
                        <td>{{consumerDetails.BirthDate}}</td>
                        <th>Email Address:</th>
                        <td>{{consumerDetails.EmailAddress}}</td>
                    </tr>
                    <tr>
                        <th>Marital Status:</th>
                        <td>{{consumerDetails.MaritalStatusDesc}}</td>
                        <th>Current Employer:</th>
                        <td>{{consumerDetails.EmployerDetail}}</td>
                    </tr>
                </tbody>
            </table>
            <div>
                <h4>Notes</h4>
                <div *ngIf="!consumerDetails.Notes">
                    <button class="btn btn-primary" (click)="addNote(consumerDetails, consumerDetails.nodeId)">Add notes</button>
                </div>
                <div *ngIf="consumerDetails.Notes">
                    <button class="btn btn-sm btn-primary" (click)="editNote(consumerDetails.Notes.dataId, $event)">Edit</button>
                    <div [innerHTML]="consumerDetails.Notes.content"></div>
                </div>
            </div>
        </div>
        <div class="form-group bg-white p-3 m-3 rounded-lg shadow">
            <h3>Potential Fraud Indicators</h3>
            <table class="table table-hover">
                <tbody>
                    <tr>
                        <th scope="row">ID No. Verified Status at Home Affairs:</th>
                        <td>{{fraudIndicators.HomeAffairsVerificationYN}}</td>
                    </tr>
                    <tr>
                        <th scope="row">ID No. Deceased Status at Home Affairs:</th>
                        <td>{{fraudIndicators.HomeAffairsDeceasedStatus}}</td>
                    </tr>
                </tbody>
            </table>
            <div>
                <h4>Notes</h4>
                <div *ngIf="!fraudIndicators.Notes">
                    <button class="btn btn-primary" (click)="addNote(fraudIndicators, fraudIndicators.nodeId)">Add notes</button>
                </div>
                <div *ngIf="fraudIndicators.Notes">
                    <button class="btn btn-sm btn-primary" (click)="editNote(fraudIndicators.Notes.dataId, $event)">Edit</button>
                    <div [innerHTML]="fraudIndicators.Notes.content"></div>
                </div>
            </div>
        </div>
        <div class="form-group bg-white p-3 m-3 rounded-lg shadow">
            <h3>Address History</h3>
            <table class="table table-hover">
                <thead>
                    <tr>
                        <th>Bureau Update</th>
                        <th>Type</th>
                        <th>Line 1</th>
                        <th>Line 2</th>
                        <th>Line 3</th>
                        <th>Line 4</th>
                        <th>Postal Code</th>
                    </tr>
                </thead>
                <tbody *ngIf="addressHistory.items">
                    <tr *ngFor="let address of addressHistory.items">
                        <td>{{address.LastUpdatedDate}}</td>
                        <td>{{address.AddressType}}</td>
                        <td>{{address.Address1}}</td>
                        <td>{{address.Address2}}</td>
                        <td>{{address.Address3}}</td>
                        <td>{{address.Address4}}</td>
                        <td>{{address.PostalCode}}</td>
                    </tr>
                </tbody>
                <tbody *ngIf="!addressHistory.items">
                    <tr>
                        <td colspan="5">No records found</td>
                    </tr>
                </tbody>
            </table>

            <div>
                <h4>Notes</h4>
                <div *ngIf="!addressHistory.Notes">
                    <button class="btn btn-primary" (click)="addNote(addressHistory, addressHistory.nodeId)">Add notes</button>
                </div>
                <div *ngIf="addressHistory.Notes">
                    <button class="btn btn-sm btn-primary" (click)="editNote(addressHistory.Notes.dataId, $event)">Edit</button>
                    <div [innerHTML]="addressHistory.Notes.content"></div>
                </div>
            </div>
        </div>
        <div class="form-group bg-white p-3 m-3 rounded-lg shadow">
            <h3>Contact No. History</h3>
            <table class="table table-hover">
                <thead>
                    <tr>
                        <th>Bureau Update</th>
                        <th>Type</th>
                        <th>Telephone No.</th>
                    </tr>
                </thead>
                <tbody *ngIf="contactNumberHistory.items">
                    <tr *ngFor="let number of contactNumberHistory.items">
                        <td>{{number.LastUpdatedDate}}</td>
                        <td>{{number.TelephoneType}}</td>
                        <td>{{number.TelephoneNo}}</td>
                    </tr>
                </tbody>
                <tbody *ngIf="!contactNumberHistory.items">
                    <tr>
                        <td colspan="5">No records found</td>
                    </tr>
                </tbody>
            </table>
            <div>
                <h4>Notes</h4>
                <div *ngIf="!contactNumberHistory.Notes">
                    <button class="btn btn-primary" (click)="addNote(contactNumberHistory, contactNumberHistory.nodeId)">Add notes</button>
                </div>
                <div *ngIf="contactNumberHistory.Notes">
                    <button class="btn btn-sm btn-primary" (click)="editNote(contactNumberHistory.Notes.dataId, $event)">Edit</button>
                    <div [innerHTML]="contactNumberHistory.Notes.content"></div>
                </div>
            </div>
        </div>
        <div class="form-group bg-white p-3 m-3 rounded-lg shadow">
            <h3>Home Linkage Information</h3>
            <table class="table table-hover">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Surname</th>
                        <th>Home Number</th>
                        <th>Work Number</th>
                        <th>Cell Number</th>
                    </tr>
                </thead>
                <tbody *ngIf="telephoneLinkageHome.items">
                    <tr *ngFor="let number of telephoneLinkageHome.items">
                        <td>{{number.ConsumerName}}</td>
                        <td>{{number.Surname}}</td>
                        <td>{{number.HomeTelphone}}</td>
                        <td>{{number.WorkTelephone}}</td>
                        <td>{{number.CellularNo}}</td>
                    </tr>
                </tbody>
                <tbody *ngIf="!telephoneLinkageHome.items">
                    <tr>
                        <td colspan="5">No records found</td>
                    </tr>
                </tbody>
            </table>
            <div>
                <h4>Notes</h4>
                <div *ngIf="!telephoneLinkageHome.Notes">
                    <button class="btn btn-primary" (click)="addNote(telephoneLinkageHome, telephoneLinkageHome.nodeId)">Add notes</button>
                </div>
                <div *ngIf="telephoneLinkageHome.Notes">
                    <button class="btn btn-sm btn-primary" (click)="editNote(telephoneLinkageHome.Notes.dataId, $event)">Edit</button>
                    <div [innerHTML]="telephoneLinkageHome.Notes.content"></div>
                </div>
            </div>
        </div>
        <div class="form-group bg-white p-3 m-3 rounded-lg shadow">
            <h3>Cellular Linkage Information</h3>
            <table class="table table-hover">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Surname</th>
                        <th>Home Number</th>
                        <th>Work Number</th>
                        <th>Cell Number</th>
                    </tr>
                </thead>
                <tbody *ngIf="telephoneLinkageCellular.items">
                    <tr *ngFor="let number of telephoneLinkageCellular.items">
                        <td>{{number.ConsumerName}}</td>
                        <td>{{number.Surname}}</td>
                        <td>{{number.HomeTelphone}}</td>
                        <td>{{number.WorkTelephone}}</td>
                        <td>{{number.CellularNo}}</td>
                    </tr>
                </tbody>
                <tbody *ngIf="!telephoneLinkageCellular.items">
                    <tr>
                        <td colspan="5">No records found</td>
                    </tr>
                </tbody>
            </table>
            <div>
                <h4>Notes</h4>
                <div *ngIf="!telephoneLinkageCellular.Notes">
                    <button class="btn btn-primary" (click)="addNote(telephoneLinkageCellular, telephoneLinkageCellular.nodeId)">Add notes</button>
                </div>
                <div *ngIf="telephoneLinkageCellular.Notes">
                    <button class="btn btn-sm btn-primary" (click)="editNote(telephoneLinkageCellular.Notes.dataId, $event)">Edit</button>
                    <div [innerHTML]="telephoneLinkageCellular.Notes.content"></div>
                </div>
            </div>
        </div>
        <div class="form-group bg-white p-3 m-3 rounded-lg shadow">
            <h3>Work Linkage Information</h3>
            <table class="table table-hover">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Surname</th>
                        <th>Home Number</th>
                        <th>Work Number</th>
                        <th>Cell Number</th>
                    </tr>
                </thead>
                <tbody *ngIf="telephoneLinkageWork.items">
                    <tr *ngFor="let number of telephoneLinkageWork.items">
                        <td>{{number.ConsumerName}}</td>
                        <td>{{number.Surname}}</td>
                        <td>{{number.HomeTelphone}}</td>
                        <td>{{number.WorkTelephone}}</td>
                        <td>{{number.CellularNo}}</td>
                    </tr>
                </tbody>
                <tbody *ngIf="!telephoneLinkageWork.items">
                    <tr>
                        <td colspan="5">No records found</td>
                    </tr>
                </tbody>
            </table>
            <div>
                <h4>Notes</h4>
                <div *ngIf="!telephoneLinkageWork.Notes">
                    <button class="btn btn-primary" (click)="addNote(telephoneLinkageWork, telephoneLinkageWork.nodeId)">Add notes</button>
                </div>
                <div *ngIf="telephoneLinkageWork.Notes">
                    <button class="btn btn-sm btn-primary" (click)="editNote(telephoneLinkageWork.Notes.dataId, $event)">Edit</button>
                    <div [innerHTML]="telephoneLinkageWork.Notes.content"></div>
                </div>
            </div>
        </div>
        <div class="form-group bg-white p-3 m-3 rounded-lg shadow">
            <h3>Email Adress History</h3>
            <table class="table table-hover">
                <thead>
                    <tr>
                        <th>Bureau update</th>
                        <th>Email Address</th>
                    </tr>
                </thead>
                <tbody *ngIf="emailAddressHistory.items">
                    <tr *ngFor="let item of emailAddressHistory.items">
                        <td>{{item.LastUpdatedDate}}</td>
                        <td>{{item.EmailAddress}}</td>
                    </tr>
                </tbody>
                <tbody *ngIf="!emailAddressHistory.items">
                    <tr>
                        <td colspan="5">No records found</td>
                    </tr>
                </tbody>
            </table>
            <div>
                <h4>Notes</h4>
                <div *ngIf="!emailAddressHistory.Notes">
                    <button class="btn btn-primary" (click)="addNote(emailAddressHistory, emailAddressHistory.nodeId)">Add notes</button>
                </div>
                <div *ngIf="emailAddressHistory.Notes">
                    <button class="btn btn-sm btn-primary" (click)="editNote(emailAddressHistory.Notes.dataId, $event)">Edit</button>
                    <div [innerHTML]="emailAddressHistory.Notes.content"></div>
                </div>
            </div>
        </div>
        <div class="form-group bg-white p-3 m-3 rounded-lg shadow">
            <h3>Employment History</h3>
            <table class="table table-hover">
                <thead>
                    <tr>
                        <th>Bureau Update</th>
                        <th>Employer</th>
                    </tr>
                </thead>
                <tbody *ngFor="let item of employerDetails.items">
                    <tr>
                        <td>{{item.EmployerDetail}}</td>
                        <td>{{item.LastUpdatedDate}}</td>
                    </tr>
                </tbody>
            </table>
            <div>
                <h4>Notes</h4>
                <div *ngIf="!employerDetails.Notes">
                    <button class="btn btn-primary" (click)="addNote(employerDetails, employerDetails.nodeId)">Add notes</button>
                </div>
                <div *ngIf="employerDetails.Notes">
                    <button class="btn btn-sm btn-primary" (click)="editNote(employerDetails.Notes.dataId, $event)">Edit</button>
                    <div [innerHTML]="employerDetails.Notes.content"></div>
                </div>
            </div>
        </div>
        <div class="form-group bg-white p-3 m-3 rounded-lg shadow">
            <h3>Financial Records</h3>
            <table class="table table-hover">
                <thead>
                    <tr>
                        <th>Financial Records</th>
                        <th>Findings</th>
                    </tr>
                </thead>
                <tbody *ngFor="let item of financialDetails.items">
                    <tr [class.text-danger]="item.style">
                        <td>{{item.type}}</td>
                        <td>{{item.value}}</td>
                    </tr>
                </tbody>
            </table>
            <div>
                <h4>Notes</h4>
                <div *ngIf="!financialDetails.Notes">
                    <button class="btn btn-primary" (click)="addNote(financialDetails, financialDetails.nodeId)">Add notes</button>
                </div>
                <div *ngIf="financialDetails.Notes">
                    <button class="btn btn-sm btn-primary" (click)="editNote(financialDetails.Notes.dataId, $event)">Edit</button>
                    <div [innerHTML]="financialDetails.Notes.content"></div>
                </div>
            </div>
        </div>
        <div *ngIf="adverseInfo.data" class="form-group bg-white p-3 m-3 rounded-lg shadow">
            <h3 class="text-danger">{{adverseInfo.title}}</h3>

            <table class="table table-hover text-danger">
                <tbody *ngFor="let financialRecordDetail of adverseInfo.data">
                    <tr>
                        <th>{{financialRecordDetail.name}}</th>
                        <td>{{financialRecordDetail.data}}</td>
                    </tr>
                </tbody>
            </table>
            <div>
                <h4>Notes</h4>
                <div *ngIf="!adverseInfo.Notes">
                    <button class="btn btn-primary" (click)="addNote(adverseInfo, adverseInfo.nodeId)">Add notes</button>
                </div>
                <div *ngIf="adverseInfo.Notes">
                    <button class="btn btn-sm btn-primary" (click)="editNote(adverseInfo.Notes.dataId, $event)">Edit</button>
                    <div [innerHTML]="adverseInfo.Notes.content"></div>
                </div>
            </div>
        </div>
        <div *ngIf="judgment.data" class="form-group bg-white p-3 m-3 rounded-lg shadow">
            <h3 class="text-danger">{{judgment.title}}</h3>

            <table class="table table-hover text-danger">
                <tbody *ngFor="let financialRecordDetail of judgment.data">
                    <tr>
                        <th>{{financialRecordDetail.name}}</th>
                        <td>{{financialRecordDetail.data}}</td>
                    </tr>
                </tbody>
            </table>
            <div>
                <h4>Notes</h4>
                <div *ngIf="!judgment.Notes">
                    <button class="btn btn-primary" (click)="addNote(judgment, judgment.nodeId)">Add notes</button>
                </div>
                <div *ngIf="judgment.Notes">
                    <button class="btn btn-sm btn-primary" (click)="editNote(judgment.Notes.dataId, $event)">Edit</button>
                    <div [innerHTML]="judgment.Notes.content"></div>
                </div>
            </div>
        </div>
        <div *ngIf="debtReview.data" class="form-group bg-white p-3 m-3 rounded-lg shadow">
            <h3 class="text-danger">{{debtReview.title}}</h3>

            <table class="table table-hover text-danger">
                <tbody *ngFor="let financialRecordDetail of debtReview.data">
                    <tr>
                        <th>{{financialRecordDetail.name}}</th>
                        <td>{{financialRecordDetail.data}}</td>
                    </tr>
                </tbody>
            </table>
            <div>
                <h4>Notes</h4>
                <div *ngIf="!debtReview.Notes">
                    <button class="btn btn-primary" (click)="addNote(debtReview, debtReview.nodeId)">Add notes</button>
                </div>
                <div *ngIf="debtReview.Notes">
                    <button class="btn btn-sm btn-primary" (click)="editNote(debtReview.Notes.dataId, $event)">Edit</button>
                    <div [innerHTML]="debtReview.Notes.content"></div>
                </div>
            </div>
        </div>
        <div *ngIf="directorshipRecords" class="form-group bg-white p-3 m-3 rounded-lg shadow">
            <h3>Business Directorship</h3>
            <h3>Company/Organisation Directorship</h3>
            <table class="table">
                <tbody>
                    <tr>
                        <td colspan="4">{{directorshipRecords.details}}</td>
                    </tr>
                </tbody>
                <tbody *ngIf="directorshipRecords.items" class="border-0">
                    <tr>
                        <td class="border-0 p-0">
                            <table class="table table-hover">
                                <tbody *ngFor="let item of directorshipRecords.items">
                                    <tr>
                                        <th>Name:</th>
                                        <td>{{item.CommercialName}}</td>
                                        <th>Date Of Inception:</th>
                                        <td>{{item.AppointmentDate}}</td>
                                    </tr>
                                    <tr>
                                        <th>Address:</th>
                                        <td><span [innerHTML]="item.PhysicalAddress.content"></span>
                                            <button class="btn btn-sm btn-primary ml-2" (click)="editAddress(item.PhysicalAddress.dataId, $event)">Edit</button>
                                        </td>
                                        <th>Company Reg No.:</th>
                                        <td>{{item.RegistrationNo}}</td>
                                    </tr>
                                    <tr>
                                        <th>Industry Category:</th>
                                        <td>{{item.SICDesc}}</td>
                                        <th>Principal Status:</th>
                                        <td>{{item.DirectorStatus}}</td>
                                    </tr>
                                    <tr>
                                        <td colspan="4">
                                            <div>
                                                <h4>Notes</h4>
                                                <div *ngIf="!item.Notes">
                                                    <button class="btn btn-primary" (click)="addNote(item, item.nodeId)">Add notes</button>
                                                </div>
                                                <div *ngIf="item.Notes">
                                                    <button class="btn btn-sm btn-primary" (click)="editNote(item.Notes.dataId, $event)">Edit</button>
                                                    <div [innerHTML]="item.Notes.content"></div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="4" class="bg-light"></td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </tbody>
                <tbody *ngIf="!directorshipRecords.items">
                    <tr>
                        <td colspan="4">No records found</td>
                    </tr>
                    <tr>
                        <td colspan="4">
                            <div>
                                <h4>Notes</h4>
                                <div *ngIf="!directorshipRecords.Notes">
                                    <button class="btn btn-primary" (click)="addNote(directorshipRecords, directorshipRecords.nodeId)">Add notes</button>
                                </div>
                                <div *ngIf="directorshipRecords.Notes">
                                    <button class="btn btn-sm btn-primary" (click)="editNote(directorshipRecords.Notes.dataId, $event)">Edit</button>
                                    <div [innerHTML]="directorshipRecords.Notes.content"></div>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="form-group bg-white p-3 m-3 rounded-lg shadow">
            <h3>Disqualified Director Listings. The Registrar of Companies, in Terms of Section 218(1A) (B) of The Companies Act No. 61 of 1973</h3>
            <table class="table table-hover">
                <tbody>
                    <tr>
                        <td>{{disqualifiedDirectorshipRecords}}</td>
                    </tr>
                </tbody>
            </table>
            <!-- <div>
                <h4>Notes</h4>
                <div *ngIf="!disqualifiedDirectorshipRecords.Notes">
                    <button class="btn btn-primary" (click)="addNote(disqualifiedDirectorshipRecords.nodeId)">Add notes</button>
                </div>
                <div *ngIf="disqualifiedDirectorshipRecords.Notes">
                    <button class="btn btn-sm btn-primary" (click)="editNote(disqualifiedDirectorshipRecords.Notes.dataId, $event)">Edit</button>
                    <div [innerHTML]="disqualifiedDirectorshipRecords.Notes.content"></div>
                </div>
            </div> -->
        </div>
        <div class="form-group bg-white p-3 m-3 rounded-lg shadow">
            <h3>Property Ownership</h3>
            <table class="table table-hover">
                <tbody>
                    <tr>
                        <td colspan="4">{{propertyOwnershipRecords.details}}</td>
                    </tr>
                </tbody>
                <tbody *ngFor="let item of propertyOwnershipRecords.items">
                    <tr>
                        <th>Title Deed Number:</th>
                        <td>{{item.TitleDeedNo}}</td>
                        <th>Erf/Site No.:</th>
                        <td>{{item.ErfNo}}</td>
                    </tr>
                    <tr>
                        <th>Deeds Office:</th>
                        <td>{{item.DeedsOffice}}</td>
                        <th>Physical Address:</th>
                        <td>{{item.PhysicalAddress}}</td>
                    </tr>
                    <tr>
                        <th>Property Type:</th>
                        <td>{{item.PropertyTypeDesc}}</td>
                        <th>Extent / Size:</th>
                        <td>{{item.ErfSize}}</td>
                    </tr>
                    <tr>
                        <th>Purchase Date:</th>
                        <td>{{item.PurchaseDate}}</td>
                        <th>Purchase Price:</th>
                        <td>{{item.PurchasePriceAmt}}</td>
                    </tr>
                    <tr>
                        <th>Bond Holder:</th>
                        <td>{{item.BondHolderName}}</td>
                        <th>Bond Amount:</th>
                        <td>{{item.BondAmt}}</td>
                    </tr>
                    <tr>
                        <th>Bond Number:</th>
                        <td>{{item.BondAccountNo}}</td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td colspan="4">
                            <div>
                                <h4>Notes</h4>
                                <div *ngIf="!item.Notes">
                                    <button class="btn btn-primary" (click)="addNote(item, item.nodeId)">Add notes</button>
                                </div>
                                <div *ngIf="item.Notes">
                                    <button class="btn btn-sm btn-primary" (click)="editNote(item.Notes.dataId, $event)">Edit</button>
                                    <div [innerHTML]="item.Notes.content"></div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="4" class="bg-light"></td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="form-group bg-white p-3 m-3 rounded-lg shadow">
            <h3>Private Security Industry Regulatory Authority (PSIRA)</h3>
            <table class="table table-hover">
                <tbody>
                    <tr *ngFor="let item of psiraData.items">
                        <th>{{item.type}}</th>
                        <td>{{item.value}}</td>
                    </tr>
                </tbody>
            </table>
            <div>
                <h4>Notes</h4>
                <div *ngIf="!psiraData.Notes">
                    <button class="btn btn-primary" (click)="addNote(psiraData, psiraData.nodeId)">Add notes</button>
                </div>
                <div *ngIf="psiraData.Notes">
                    <button class="btn btn-sm btn-primary" (click)="editNote(psiraData.Notes.dataId, $event)">Edit</button>
                    <div [innerHTML]="psiraData.Notes.content"></div>
                </div>
            </div>
        </div>
        <div class="form-group bg-white p-3 m-3 rounded-lg shadow">
            <h3>Media Search</h3>
            <div *ngIf="!mediaSearch.content">
                <button class="btn btn-primary" (click)="addContent(mediaSearch, 'Media Search', parentId)">Add content</button>
            </div>
            <div *ngIf="mediaSearch.content">
                <button class="btn btn-sm btn-primary" (click)="editContent(mediaSearch.dataId, $event)">Edit</button>
                <div [innerHTML]="mediaSearch.content"></div>
            </div>
            <h4>Social Media</h4>
            <div *ngIf="!socialMedia.content">
                <button class="btn btn-primary" (click)="addContent(socialMedia, 'Social Media', parentId)">Add content</button>
            </div>
            <div *ngIf="socialMedia.content">
                <button class="btn btn-sm btn-primary" (click)="editContent(socialMedia.dataId, $event)">Edit</button>
                <div [innerHTML]="socialMedia.content"></div>
            </div>
            <h4>Media Articles</h4>
            <div *ngIf="!mediaArticles.content">
                <button class="btn btn-primary" (click)="addContent(mediaArticles, 'Media Articles', parentId)">Add content</button>
            </div>
            <div *ngIf="mediaArticles.content">
                <button class="btn btn-sm btn-primary" (click)="editContent(mediaArticles.dataId, $event)">Edit</button>
                <div [innerHTML]="mediaArticles.content"></div>
            </div>
        </div>
        <div class="form-group bg-white p-3 m-3 rounded-lg shadow">
            <h3>Link Analysis</h3>
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text" id="link-analysis-upload-addon">Upload</span>
                </div>
                <div class="custom-file">
                    <input type="file" accept=".csv" onchange="console.log(event.target.files)" class="custom-file-input" id="link-analysis-upload" aria-describedby="link-analysis-upload-addon">
                    <label class="custom-file-label" for="link-analysis-upload">Choose file</label>
                </div>
            </div>
            <div *ngIf="!linkAnalysis.content">
                <button class="btn btn-primary" (click)="addContent(linkAnalysis, 'Link Analysis', parentId)">Add content</button>
            </div>
            <div *ngIf="linkAnalysis.content">
                <button class="btn btn-sm btn-primary" (click)="editContent(linkAnalysis.dataId, $event)">Edit</button>
                <div [innerHTML]="linkAnalysis.content"></div>
            </div>
        </div>
        <div class="form-group bg-white p-3 m-3 rounded-lg shadow">
            <h3>Conclusion</h3>
            <div *ngIf="!conclusion.content">
                <button class="btn btn-primary" (click)="addContent(conclusion, 'Conclusion', parentId)">Add content</button>
            </div>
            <div *ngIf="conclusion.content">
                <button class="btn btn-sm btn-primary" (click)="editContent(conclusion.dataId, $event)">Edit</button>
                <div [innerHTML]="conclusion.content"></div>
            </div>
        </div>
    </form>
</div>
<app-data-new-modal newNode="{{newNode}}" newNodeTitle="{{newNodeTitle}}" (nodeEnd)="newNodeClosed()" (newContent)="addNewContent($event)"></app-data-new-modal>
<app-data-edit-modal editNode="{{editNode}}" editorContent="{{editorContent}}" contentId="{{contentId}}" contentTitle="{{contentTitle}}" (nodeEditEnd)="closeEdit()" (updatedContent)="updateContent($event)"></app-data-edit-modal>
<app-toast aria-live="polite" aria-atomic="true"></app-toast>