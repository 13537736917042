import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { GenerateComponent } from './generate/generate.component';
import { ReportComponent } from './report/report.component';
import { MenuComponent } from './menu/menu.component';
import { ProfileComponent } from './profile/profile.component';
import { SocialMediaComponent } from './social-media/social-media.component';
import { IndividualProfileComponent } from './individual-profile/individual-profile.component';
import { CompanyProfileComponent } from './company-profile/company-profile.component';
import { IndividualProfileMenuComponent } from './individual-profile-menu/individual-profile-menu.component';
import { ExistingProfilesIndividualComponent } from './existing-profiles-individual/existing-profiles-individual.component';
import { ProfileEditComponent } from './profile-edit/profile-edit.component';
import { ExistingReportsSocialComponent } from './existing-reports-social/existing-reports-social.component';

const routes: Routes = [
    { path: 'generate', component: GenerateComponent },
    { path: 'report', component: ReportComponent },
    { path: 'menu', component: MenuComponent },
    { path: 'login', component: LoginComponent },
    { path: 'social-media', component: SocialMediaComponent },
    { path: 'profile', component: ProfileComponent },
    { path: 'individual-profile', component: IndividualProfileComponent },
    { path: 'individual-profile-existing', component: ExistingProfilesIndividualComponent },
    { path: 'individual-profile-menu', component: IndividualProfileMenuComponent },
    { path: 'profile-edit', component: ProfileEditComponent },
    { path: 'company-profile', component: CompanyProfileComponent },
    { path: 'exising-reports-social', component: ExistingReportsSocialComponent },
    { path: '', component: MenuComponent }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }