import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';

import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';

@Component({
    selector: 'app-report',
    templateUrl: './report.component.html',
    styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit {

    loading = true;
    red = '#FF0000';
    yellow = '#FFFF00';
    green = '#008000';
    blue = '#0000FF';
    lightgrey = '#D3D3D3';
    markers: any = {
        type: 'FeatureCollection',
        features: []
    };

    // earthquakes: any = {};
    // countries: any = countries;
    pieChartTemplate: any = {

        data: [],
        view: [700, 400],

        gradient: true,
        showLegend: true,
        showLabels: true,
        isDoughnut: false,
        legendPosition: 'below',


        colorScheme: {
            domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA']
        }
    };


    engagementLineChartTemplate: any = {
        data: [],
        view: [700, 300],
        legend: true,
        showLabels: true,
        animations: true,
        xAxis: true,
        yAxis: true,
        showYAxisLabel: true,
        showXAxisLabel: true,
        xAxisLabel: 'Date',
        yAxisLabel: 'Count',
        timeline: true,
        colorScheme: {
            domain: []
        }
    };

    sentimentLineChartTemplate: any = {
        data: [],
        view: [700, 300],
        legend: true,
        showLabels: true,
        animations: true,
        xAxis: true,
        yAxis: true,
        showYAxisLabel: true,
        showXAxisLabel: true,
        xAxisLabel: 'Date',
        yAxisLabel: 'Count',
        timeline: true,
        colorScheme: {
            domain: []
        }
    };

    lineChartTemplate: any = {
        data: [],
        view: [700, 300],
        legend: true,
        showLabels: true,
        animations: true,
        xAxis: true,
        yAxis: true,
        showYAxisLabel: true,
        showXAxisLabel: true,
        xAxisLabel: 'Year',
        yAxisLabel: 'Population',
        timeline: true,

        colorScheme: {
            domain: []
        }
    };
    dissidentBarChartTemplate: any = {
        legendTitle: '',
        data: [],
        view: [700, 400],
        showXAxis: true,
        showYAxis: true,
        gradient: false,
        showLegend: true,
        showXAxisLabel: true,
        xAxisLabel: 'Gender',
        showYAxisLabel: true,
        yAxisLabel: 'Count',

        colorScheme: {
            domain: ['#011a27', '#063952', '#f0810f', '#e6df44']
        }
    };
    barChartTemplate: any = {
        view: [700, 400],
        data: [],
        showXAxis: true,
        showYAxis: true,
        gradient: true,
        showLegend: true,
        showXAxisLabel: true,
        xAxisLabel: 'Country',
        showYAxisLabel: true,
        yAxisLabel: 'Population',
        legendTitle: 'Years',

        colorScheme: {
            domain: ['#5AA454', '#C7B42C', '#AAAAAA']
        }
    };

    verticalBarChartTemplate: any = {
        data: [],
        view: [700, 400],
        showXAxis: true,
        showYAxis: true,
        gradient: false,
        showLegend: true,
        legendPosition: 'below',
        showXAxisLabel: true,
        yAxisLabel: 'Country',
        showYAxisLabel: true,
        xAxisLabel: 'Population',

        colorScheme: {
            domain: ['#5AA454', '#C7B42C', '#AAAAAA']
        },
    };
    sentimentChart: any;
    sentimentLineChart: any;
    locationBarChart: any;

    title = '';
    theme = '';
    date = '';
    riskRating = '';
    traction = '';
    rhetoric = '';
    executiveSummary = '';
    closeResult = '';
    associatedHashtags: any = '';
    topTenInfluencers: any;
    topInfluencer: any;
    subversiveSentimentSummary = '';
    topics: any = [];
    dissidentDemographicSummary = '';
    dissidentDemographicChart: any;
    dissidentDemographicEngagement = 0;
    dissidentDemographicPotentialReach = 0;
    dissidentDemographicUniqueAuthors = 0;
    riskSummary = '';
    netSentimentAnalysis = '';

    lineChartDataLabels: any = [];
    lineChartDataData: any = [];
    lineChartData: any = {};

    lineChartOptions: any = {
        options: {
            scales: {
                y: {
                    beginAtZero: true
                }
            }
        }
    };

    resultsOverTimeChartData: any = [];
    netSentimentOverTimeChartData: any = [];

    constructor(
        private activatedRoute: ActivatedRoute,
        private apiService: ApiService,
    ) { }

    ngOnInit(): void {
        this.activatedRoute.queryParams.subscribe(params => {
            const reportId = params.reportid;
            if (reportId === null) {
                console.log('we have problem');
            } else {
                this.getData(reportId);
            }

        });
    }


    setEngagementLineChartData(data: any): any {
        if (typeof data.data !== 'undefined' && data.data != null) {
            const engagementLineChart: any = this.engagementLineChartTemplate;
            const lineChartData = [];
            const colorScheme = [];
            for (let index = 0; index < data.header.v.length; index++) {
                const seriesData: Array<any> = [];
                for (const entry of data.data) {
                    const date = new Date(entry.t);
                    const dateTime = date.toLocaleDateString();
                    const tempData: any = {
                        name: dateTime,
                        value: entry.v[index]
                    };
                    seriesData.push(tempData);
                }
                colorScheme.push(this.blue);
                const lineData = {
                    name: data.header.v[index],
                    series: seriesData
                };
                lineChartData.push(lineData);
            }
            engagementLineChart.colorScheme.domain = colorScheme;
            engagementLineChart.data = lineChartData;
            return engagementLineChart;
        } else {
            return null;
        }

    }
    setSentimentLineChartData(data: any): any {
        if (typeof data.data !== 'undefined' && data.data != null) {
            const sentimentLineChart: any = this.sentimentLineChartTemplate;
            const lineChartData = [];
            const colorScheme = [];
            for (let index = 0; index < data.header.v.length; index++) {
                const seriesData: Array<any> = [];
                for (const entry of data.data) {
                    const date = new Date(entry.t);
                    const dateTime = date.toLocaleDateString();
                    const tempData: any = {
                        name: dateTime,
                        value: entry.v[index]
                    };
                    seriesData.push(tempData);
                }
                switch (data.header.v[index]) {
                    case 'POSITIVE':
                        colorScheme.push(this.green);
                        break;
                    case 'NEGATIVE':
                        colorScheme.push(this.red);
                        break;
                    case 'NEUTRAL':
                        colorScheme.push(this.yellow);
                        break;
                    case 'NONE':
                        colorScheme.push(this.lightgrey);
                        break;
                }
                const lineData = {
                    name: data.header.v[index],
                    series: seriesData
                };
                lineChartData.push(lineData);
            }
            sentimentLineChart.colorScheme.domain = colorScheme;
            sentimentLineChart.data = lineChartData;
            return sentimentLineChart;
        } else {
            return null;
        }
    }

    setDissidentDemographicBarChart(data: any): any {
        if (typeof data !== 'undefined' && data != null) {
            const barChart = this.dissidentBarChartTemplate;
            barChart.data = data;
            return barChart;
        } else {
            return null;
        }


    }

    processTopics(topicsReceived: any): void {
        for (const topic of topicsReceived) {
            const content = JSON.parse(topic.content);
            let topTenInfluencers = content.topInfluencers;
            topTenInfluencers = this.cleanArray(topTenInfluencers, 'Engagement');
            const orderedInfluencers = _.sortBy(topTenInfluencers, ['Engagement']).reverse();
            topic.topInfluencer = orderedInfluencers[0];
            topic.topTenInfluencers = topTenInfluencers;
            topic.associatedHashtags = content.associatedHashtags;
            topic.markers = this.processLocations(content.geographicDistribution, topic.name);
            topic.bounds = this.calculateBounds(content.geographicDistribution);
            topic.content = content;
            this.topics.push(topic);
        }
        this.setResultOverTimeResult();
        this.setNetSentimentOverTimeResult();
    }

    processResultsOverTime(data: any, topicName: string): Array<any> {
        const returnData: any = { name: topicName, series: [] };
        for (const moment of data) {
            if (moment[topicName] !== 'n/a') {
                returnData.series.push({ name: moment.Date, value: moment[topicName] });
            }
        }
        return returnData;
    }

    processNetSentimentOverTime(data: any, topicName: string): Array<any> {
        const returnData: any = { name: topicName, series: [] };
        for (const moment of data) {
            if (moment[topicName] !== 'n/a') {
                returnData.series.push({ name: moment.Date, value: moment[topicName] });
            }
        }
        return returnData;
    }
    setNetSentimentOverTimeResult(): void {
        for (const topic of this.topics) {
            this.netSentimentOverTimeChartData.push(this.processNetSentimentOverTime(topic.content.netSentimentOverTime, topic.name));
        }
    }
    setResultOverTimeResult(): void {
        for (const topic of this.topics) {
            this.resultsOverTimeChartData.push(this.processResultsOverTime(topic.content.resultsOverTime, topic.name));
        }
    }

    processLocations(locations: any, topicName: string): any {
        const markers: any = {
            type: 'FeatureCollection',
            features: []
        };
        const locationsLength = locations.length;
        for (let lc = 0; lc < locationsLength; lc++) {
            for (let mc = 0; mc < locations[lc].count; mc++) {
                markers.features.push({
                    type: 'Feature',
                    properties: {
                        'Primary ID': locations[lc].name + mc,
                        'Secondary ID': topicName
                    },
                    geometry: {
                        type: 'Point',
                        coordinates: [locations[lc].coords.lng, locations[lc].coords.lat]
                    }
                });
            }

        }
        return markers;
    }

    calculateBounds(locations: any): any {
        const locationsLength = locations.length;
        const longs: Array<number> = [];
        const lats: Array<number> = [];
        for (let lc = 0; lc < locationsLength; lc++) {
            longs.push(locations[lc].coords.lng);
            lats.push(locations[lc].coords.lat);
        }
        longs.sort((a, b): any => {
            return a - b;
        });
        lats.sort((a, b): any => {
            return b - a;
        });
        return [[longs[0], lats[0]], [longs[longs.length - 1], lats[lats.length - 1]]];
    }


    getData(reportId: string): void {
        this.apiService.getReport(reportId).subscribe((result: any) => {
            if (result.success) {
                this.title = result.data.reportName;
                this.theme = result.data.analysisTableTheme;
                this.date = result.data.date;
                this.riskRating = result.data.analysisTableRiskRating;
                this.traction = result.data.analysisTableTraction;
                this.rhetoric = result.data.analysisTableRhetoric;
                this.executiveSummary = result.data.executiveSummary;
                this.subversiveSentimentSummary = result.data.subversiveSentimentSummary;
                this.dissidentDemographicSummary = result.data.dissidentDemographicSummary;
                this.riskSummary = result.data.riskSummary;
                this.processTopics(result.data.topics);
            }
            this.loading = false;
        });
    }

    cleanArray(array: any, elementToLookFor: any): Array<any> {
        for (const count in array) {
            if (typeof array[count][elementToLookFor] === 'undefined' || array[count][elementToLookFor] === null) {
                array.splice(count, 1);
            } else {
                array[count][elementToLookFor] = Number(array[count][elementToLookFor]);
            }
        }
        return array;
    }


    onSelect(data: any): void {
        console.log('Item clicked', JSON.parse(JSON.stringify(data)));
    }

    onActivate(data: any): void {
        console.log('Activate', JSON.parse(JSON.stringify(data)));
    }

    onDeactivate(data: any): void {
        console.log('Deactivate', JSON.parse(JSON.stringify(data)));
    }

}
