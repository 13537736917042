import { Component, OnInit } from '@angular/core';
import { LogoutService } from 'src/app/services/logout.service';

@Component({
    selector: 'app-individual-profile-menu',
    templateUrl: './individual-profile-menu.component.html',
    styleUrls: ['./individual-profile-menu.component.css']
})
export class IndividualProfileMenuComponent implements OnInit {

    constructor(private logoutService: LogoutService) { }

    ngOnInit(): void {
    }
}
