import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-summary',
    templateUrl: './summary.component.html',
    styleUrls: ['./summary.component.css']
})
export class SummaryComponent implements OnInit {
    @Input() title: any;
    @Input() theme: any;
    @Input() date: any;
    @Input() riskRating: any;
    @Input() traction: any;
    @Input() rhetoric: any;
    @Input() executiveSummary: any;

    constructor() { }

    ngOnInit(): void {
    }

}
