import { Component, OnInit } from '@angular/core';
import { LogoutService } from 'src/app/services/logout.service';

@Component({
    selector: 'app-logout',
    templateUrl: './logout.component.html',
    styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {

    constructor(private logoutService: LogoutService) { }

    ngOnInit(): void {
    }

    logout(): void {
        this.logoutService.logout();
    }
}
