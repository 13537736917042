<ng-template #modalEditData let-modal>
    <div class="modal-header">
        <h2 class="modal-title" id="modal-basic-title">Editing {{contentTitle}}</h2>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.close('cancel')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <quill-editor [(ngModel)]="editorContent" [debounceTime]="1000" class="mt-2" id="{{ 'content-edit-' + contentId}}"></quill-editor>
        <div class="mt-4 d-flex justify-content-center">
            <!-- <button class="btn btn-primary content-edit-submit me-3" [attr.data-id]="node.id" (click)="save($event)">Save</button>
            <button class="btn btn-danger content-edit-cancel" (click)="cancelEdit()">Cancel</button> -->
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="modal.close('save')">Save</button>
        <button type="button" class="btn btn-primary" (click)="modal.close('cancel')">Cancel</button>
    </div>
</ng-template>