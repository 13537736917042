import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { GenerateComponent } from './generate/generate.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReportComponent } from './report/report.component';
import { TokenInterceptorService } from 'src/app/services/token-interceptor.service';
import { LoadingInterceptorService } from 'src/app/services/loading-interceptor.service';
import { MenuComponent } from './menu/menu.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';

// import { NgxMapLibreGLModule } from 'ngx-maplibre-gl';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';

import { QuillModule } from 'ngx-quill';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { WhatComponent } from './components/what/what.component';
import { WhoComponent } from './components/who/who.component';
import { WhereComponent } from './components/where/where.component';
import { WhenComponent } from './components/when/when.component';
import { DissidentDemographicComponent } from './components/dissident-demographic/dissident-demographic.component';
import { SummaryComponent } from './components/summary/summary.component';
import { RiskSummaryComponent } from './components/risk-summary/risk-summary.component';
import { ProfileComponent } from './profile/profile.component';
import { SocialMediaComponent } from './social-media/social-media.component';
import { IndividualProfileComponent } from './individual-profile/individual-profile.component';
import { CompanyProfileComponent } from './company-profile/company-profile.component';
import { IndividualProfileMenuComponent } from './individual-profile-menu/individual-profile-menu.component';
import { ExistingProfilesIndividualComponent } from './existing-profiles-individual/existing-profiles-individual.component';
import { LogoutComponent } from './components/logout/logout.component';
import { ProfileEditComponent } from './profile-edit/profile-edit.component';
import { BackComponent } from './components/back/back.component';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { ExistingReportsSocialComponent } from './existing-reports-social/existing-reports-social.component';
import { DataNewModalComponent } from './components/data-new-modal/data-new-modal.component';
import { DataEditModalComponent } from './components/data-edit-modal/data-edit-modal.component';
import { ToastComponent } from './components/toast/toast.component';
import { RouteReuseStrategy } from '@angular/router';

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        GenerateComponent,
        ReportComponent,
        MenuComponent,
        WhatComponent,
        WhoComponent,
        WhereComponent,
        WhenComponent,
        DissidentDemographicComponent,
        SummaryComponent,
        RiskSummaryComponent,
        ProfileComponent,
        SocialMediaComponent,
        IndividualProfileComponent,
        CompanyProfileComponent,
        IndividualProfileMenuComponent,
        ExistingProfilesIndividualComponent,
        LogoutComponent,
        ProfileEditComponent,
        BackComponent,
        LoadingSpinnerComponent,
        ExistingReportsSocialComponent,
        DataNewModalComponent,
        DataEditModalComponent,
        ToastComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        NgbModule,
        FormsModule,
        ReactiveFormsModule,
        NgxChartsModule,
        BrowserAnimationsModule,
        // NgxMapLibreGLModule
        NgxMapboxGLModule.withConfig({
            accessToken: 'pk.eyJ1Ijoic2ltb250b3BpY3dvcngiLCJhIjoiY2t0azRybnJxMDNjbDJwbWtvNzdrczMwdSJ9.o04liKgWv8oyaz__svQb7Q'
        }),
        QuillModule.forRoot()
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptorService,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LoadingInterceptorService,
            multi: true
        },
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
