import { Component, OnInit, Input } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import * as shape from 'd3-shape';

@Component({
    selector: 'app-when',
    templateUrl: './when.component.html',
    styleUrls: ['./when.component.css']
})
export class WhenComponent implements OnInit {
    closeResult = '';
    @Input() topics: any;
    @Input() subversiveSentimentSummary: any;
    @Input() resultsOverTimeChartData: any;
    @Input() netSentimentOverTimeChartData: any;

    resultsOverTimeChart: any = {
        view: [1100, 500],
        legend: true,
        legendTitle: 'Results over time',
        showLabels: true,
        animations: true,
        xAxis: true,
        yAxis: true,
        showYAxisLabel: true,
        showXAxisLabel: true,
        xAxisLabel: 'Time',
        yAxisLabel: 'Results',
        showGridLines: true,
        timeline: true,
        results: [],
        colorScheme: 'vivid',
        curve: shape.curveBasis
        // colorScheme: {
        //     domain: ['#5AA454', '#E44D25', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5']
        // }
    };

    netSentimentOverTimeChart: any = {
        view: [1100, 500],
        legend: true,
        legendTitle: 'Results over time',
        showLabels: true,
        animations: true,
        xAxis: true,
        yAxis: true,
        showYAxisLabel: true,
        showXAxisLabel: true,
        xAxisLabel: 'Time',
        yAxisLabel: 'Results',
        showGridLines: true,
        timeline: true,
        results: [],
        colorScheme: 'vivid',
        curve: shape.curveBasis
    };

    constructor(
        private modal: NgbModal
    ) { }

    ngOnInit(): void {
        this.resultsOverTimeChart.results = this.resultsOverTimeChartData;
        this.netSentimentOverTimeChart.results = this.netSentimentOverTimeChartData;
    }


    open(content: any): void {
        this.modal.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'xl', scrollable: true }).result.then((result) => {
            this.closeResult = 'Closed with: ' + result;
        }, (reason) => {
            this.closeResult = 'Dismissed ' + this.getDismissedReason(reason);
        });
    }

    private getDismissedReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return 'with ' + reason;
        }
    }

    onSelect(data: any): void {
        console.log('Item clicked', JSON.parse(JSON.stringify(data)));
    }

    onActivate(data: any): void {
        console.log('Activate', JSON.parse(JSON.stringify(data)));
    }

    onDeactivate(data: any): void {
        console.log('Deactivate', JSON.parse(JSON.stringify(data)));

    }
}
