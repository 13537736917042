import { Component, ComponentFactoryResolver, OnInit } from '@angular/core';
import { LogoutService } from 'src/app/services/logout.service';
import { ApiService } from 'src/app/services/api.service';

import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-generate',
    templateUrl: './generate.component.html',
    styleUrls: ['./generate.component.css']
})
export class GenerateComponent implements OnInit {
    loading = true;
    reportName = '';
    subversiveSentimentSummary = '';
    netSentimentAnalysis = '';
    socialPlatforms: Array<any> = [];
    topics: Array<any> = [];
    topicToAdd = '';
    topicToAddAnalysis = '';
    socialPlatformToAdd = '';
    socialPlatformToAddPercentage = 0;
    remainingPercentage = 100;
    dissidentDemographicSummary = '';
    genderMale = 0;
    genderFemale = 0;
    engagement = '';
    uniqueAuthors = '';
    potentialReach = '';
    associatedHashtagAnalysis = '';
    themeAnalysis = '';
    twitterHandle = '';
    provocateurSummary = '';
    riskSummary = '';
    analysisTableTheme = '';
    analysisTableRiskRating = '';
    analysisTableTraction = '';
    analysisTableRhetoric = '';
    executiveSummary = '';
    submitted = false;
    reportLink = '/report/';

    closeResult = '';

    constructor(
        private modal: NgbModal,
        private logoutService: LogoutService,
        private apiService: ApiService
    ) { }

    ngOnInit(): void {
        this.loading = false;
        if (localStorage.getItem('id') === null || localStorage.getItem('jwt') === null) {
            this.logoutService.logout();
        }
    }

    create(formReference: any): void {
        this.loading = true;
        const submitData = formReference.value;
        this.socialPlatforms.forEach((element) => {
            submitData['socialPlatform-' + element.name] = element.percentage;
        });
        submitData.topics = this.topics;
        this.apiService.save(submitData).subscribe((result: any) => {
            if (result.success) {
                this.reportLink += '?reportid=' + result.data;
            }
            this.loading = false;
        });

    }
    addTopic(): void {
        if (this.topicToAdd !== '' && this.topicToAddAnalysis !== '') {
            this.topics.push(
                {
                    name: this.topicToAdd,
                    themeAnalysis: this.topicToAddAnalysis
                }
            );
            this.topicToAdd = '';
            this.topicToAddAnalysis = '';
        } else {
            console.log('not enough data');
        }
    }

    close(id: string): void {
        const element: any = document.getElementById(id);
        element.classList.add('d-none');
    }

    open(id: string): void {
        const element: any = document.getElementById(id);
        element.classList.remove('d-none');
    }

    private getDismissedReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return 'with ' + reason;
        }
    }
    getHeaderArray(csvRecordsArr: any): Array<any> {
        const headers = (csvRecordsArr[0]).split(',');
        const headerArray = [];
        for (const header of headers) {
            headerArray.push(header);
        }
        // for (let j = 0; j < headers.length; j++) {
        //     headerArray.push(headers[j]);
        // }
        return headerArray;
    }

    cleanCsvArray(data: any): any {
        for (let i = 1; i < data.length; i++) {
            const currentRecord = data[i].split(',');
            if (currentRecord.length !== 14) {
                if (currentRecord.length === 0) {
                    data.splice(i, 1);
                }
                for (let cr = 0; cr < currentRecord.length; cr++) {
                    if (currentRecord[cr].indexOf('\'') !== -1) {
                        for (let tc = 1; tc < 100; tc++) {
                            if (currentRecord[cr + tc].indexOf('\'') !== -1) {
                                currentRecord[cr] += ',' + currentRecord[cr + tc];
                                if (tc === 1) {
                                    currentRecord.splice(cr + 1, 1);
                                } else {
                                    currentRecord.splice(cr + 1, tc);
                                }
                                break;
                            } else {
                                currentRecord[cr] += ',' + currentRecord[cr + tc];
                            }
                        }
                    }
                }
            }
            data[i] = currentRecord;

        }
        return data;
    }

    getTopTenDataRecordsArrayFromCSVFile(csvRecordsArray: any, headerLength: any): any {
        const csvArr = [];
        csvRecordsArray = this.cleanCsvArray(csvRecordsArray);

        for (let i = 1; i < csvRecordsArray.length; i++) {
            const currentRecord = csvRecordsArray[i];
            if (currentRecord.length === headerLength) {
                const csvRecord: any = {};
                csvRecord.Labels = currentRecord[0].trim();
                csvRecord.Avatar = currentRecord[1].trim();
                csvRecord.Rank = currentRecord[2].trim();
                csvRecord.Authors = currentRecord[3].trim();
                csvRecord.Influencers = currentRecord[4].trim();
                csvRecord['Source Type'] = currentRecord[5].trim();
                csvRecord.Posts = currentRecord[6].trim();
                csvRecord.Reach = currentRecord[7].trim();
                csvRecord.ReachPerResult = currentRecord[8].trim();
                csvRecord.Engagement = currentRecord[9].trim();
                csvRecord.EngagementPerResult = currentRecord[10].trim();
                csvRecord.Profile = currentRecord[11].trim();
                csvRecord.Biography = currentRecord[12].trim();
                csvRecord.Country = currentRecord[13].trim();
                csvArr.push(csvRecord);
            }
        }
        return csvArr;
    }

    processTopTenFile($event: any): void {
        const topicName = $event.target.parentElement.parentElement.parentElement.previousElementSibling.innerText;
        const file: File = $event.target.files[0];

        if (file) {
            const fileName = file.name;
            const fileReader = new FileReader();
            fileReader.onload = (e) => {
                for (const topic of this.topics) {
                    if (topic.name === topicName) {
                        topic.topTenFilename = fileName;
                        const fileData: any = fileReader.result;
                        const csvRecordsArray: Array<any> = fileData.split(/\r\n|\n/);
                        const headersRow = this.getHeaderArray(csvRecordsArray);
                        const records = this.getTopTenDataRecordsArrayFromCSVFile(csvRecordsArray, headersRow.length);
                        topic.topTenFileData = records;
                    }
                }
            };
            fileReader.readAsText(file);
        }
    }

    getAssociateHashtagDataRecordsArrayFromCSVFile(csvRecordsArray: any, headerLength: any): Array<any> {
        const csvArr = [];
        csvRecordsArray = this.cleanCsvArray(csvRecordsArray);

        for (let i = 1; i < csvRecordsArray.length; i++) {
            const currentRecord = csvRecordsArray[i];
            if (currentRecord.length === headerLength) {
                const csvRecord: any = {};
                csvRecord.Group = currentRecord[0].trim();
                csvRecord.Item = currentRecord[1].trim();
                csvRecord.TotalHits = currentRecord[2].trim();
                csvRecord.PositiveHits = currentRecord[3].trim();
                csvRecord.NeutralHits = currentRecord[4].trim();
                csvRecord.NegativeHits = currentRecord[5].trim();
                csvRecord.ItemColor = currentRecord[6].trim();
                csvArr.push(csvRecord);
            }
        }
        return csvArr;
    }
    processAssociatedHashtagsFile($event: any): void {
        const topicName = $event.target.parentElement.parentElement.parentElement.parentElement.firstElementChild.innerText;
        const file: File = $event.target.files[0];

        if (file) {
            const fileName = file.name;
            const fileReader = new FileReader();
            fileReader.onload = (e) => {
                for (const topic of this.topics) {
                    if (topic.name === topicName) {
                        topic.associatedHashtagsFilename = fileName;
                        const fileData: any = fileReader.result;
                        const csvRecordsArray: Array<any> = fileData.split(/\r\n|\n/);
                        const headersRow = this.getHeaderArray(csvRecordsArray);
                        const records = this.getAssociateHashtagDataRecordsArrayFromCSVFile(csvRecordsArray, headersRow.length);
                        topic.associatedHashtagsFileData = records;
                    }
                }
            };
            fileReader.readAsText(file);
        }

    }



    addSocialPlatform(): void {
        if (this.socialPlatformToAdd !== '' && this.socialPlatformToAddPercentage !== 0) {
            this.socialPlatforms.push({ name: this.socialPlatformToAdd, percentage: this.socialPlatformToAddPercentage });
            this.remainingPercentage -= this.socialPlatformToAddPercentage;
            this.socialPlatformToAdd = '';
            this.socialPlatformToAddPercentage = 0;
        } else {
            console.log('not enough data');
        }
    }

    objectToUrlString(object: any): string {
        return Object.entries(object).map(
            ([key]) => `${encodeURIComponent(key)}=${encodeURIComponent(object[key])}`
        ).join('&');
    }

}
