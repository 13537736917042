<h2>Dissident demographic</h2>
<h5 class="mt-2">SUMMARY</h5>
<p>
    {{dissidentDemographicSummary}}
</p>
<div class="mt-3">
</div>
<div class="mt-3">
    <ngb-accordion #acc="ngbAccordion" [closeOthers]="true">
        <ngb-panel *ngFor="let topic of topics">
            <ng-template ngbPanelTitle>{{topic.name}}</ng-template>
            <ng-template ngbPanelContent>

                <div class="pb-5 mx-auto bar-chart-container">
                    <h4 class="pb-3 pt-3 text-center">Gender breakdown</h4>
                    <ngx-charts-bar-horizontal *ngIf="topic.genderData" [results]="topic.genderData" [scheme]="genderBarChartTemplate.colourScheme" [view]="genderBarChartTemplate.view" [legendTitle]="genderBarChartTemplate.legendTitle" [legend]="genderBarChartTemplate.legend">
                    </ngx-charts-bar-horizontal>
                </div>

                <div class="mt-3 mb-5 mx-auto bar-chart-container">
                    <h4 class="pb-2 pt-4 text-center">Gender breakdown</h4>
                    <ngx-charts-bar-vertical *ngIf="topic.ageData" [results]="topic.ageData" [scheme]="ageBarChartTemplate.colourScheme" [view]="ageBarChartTemplate.view" [legendTitle]="ageBarChartTemplate.legendTitle" [legend]="ageBarChartTemplate.legend" [xAxisLabel]="ageBarChartTemplate.xAxisLabel"
                        [yAxisLabel]="ageBarChartTemplate.yAxisLabel">
                    </ngx-charts-bar-vertical>
                </div>
                <div class="mt-5">
                    <table class="table">
                        <thead>
                            <th scope="col">Engagement</th>
                            <th scope="col">Potential reach</th>
                            <th scope="col">Unique authors</th>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{{topic.content.engagement[0].Engagement}}</td>
                                <td>{{topic.content.potentialReach[0].Reach}}</td>
                                <td>{{topic.content.uniqueAuthors[0].Authors}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </ng-template>
        </ngb-panel>
    </ngb-accordion>
</div>