import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';

@Component({
    selector: 'app-existing-reports-social',
    templateUrl: './existing-reports-social.component.html',
    styleUrls: ['./existing-reports-social.component.css']
})
export class ExistingReportsSocialComponent implements OnInit {
    reports: Array<any> = [];
    constructor(private apiService: ApiService) { }

    ngOnInit(): void {
        this.getData();
    }

    getData(): any {
        this.apiService.getReportList().subscribe((resultData: any) => {
            console.log(resultData);
            this.reports = resultData.data;
        });
    }

}
