<div class="action-container d-flex">
    <img src="../../assets/images/Where.svg" class="img-fluid" (click)="open(modalWhere)">
    <button data-toggle="modal" class="bg-primary btn btn-primary btn-lg align-self-center" (click)="open(modalWhere)">Where?</button>
</div>
<ng-template #modalWhere let-modal>
    <div class="modal-header">
        <h2 class="modal-title" id="modal-basic-title">LOCATION</h2>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
    </div>
    <div class="modal-body">
        <ngb-accordion #acc="ngbAccordion" [closeOthers]="true">
            <ngb-panel *ngFor="let topic of topics">
                <ng-template ngbPanelTitle>{{topic.name}}</ng-template>
                <ng-template ngbPanelContent>
                    <mgl-map [style]="whereMap.style" [zoom]="whereMap.zoom" [center]="whereMap.center" [bounds]="topic.bounds">

                        <mgl-geojson-source id="{{topic.name + 'markers'}}" [data]="topic.markers" [cluster]="true" [clusterMaxZoom]="14" [clusterRadius]="50">
                        </mgl-geojson-source>
                        <mgl-layer id="clusters" type="circle" source="{{topic.name + 'markers'}}" [filter]="['has', 'point_count']" [paint]="{
                                'circle-color': {
                                property: 'point_count',
                                type: 'interval',
                                stops: [
                                    [0, '#51bbd6'],
                                    [100, '#f1f075'],
                                    [750, '#f28cb1']
                                ]
                                },
                                'circle-radius': {
                                property: 'point_count',
                                type: 'interval',
                                stops: [
                                    [0, 20],
                                    [100, 30],
                                    [750, 40]
                                ]
                                }
                            }">
                        </mgl-layer>

                        <mgl-layer id="cluster-count" type="symbol" source="{{topic.name + 'markers'}}" [filter]="['has', 'point_count']" [layout]="{
                                'text-field': '{point_count_abbreviated}',
                                'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
                                'text-size': 12
                            }">
                        </mgl-layer>
                        <mgl-layer id="unclustered-point" type="circle" source="{{topic.name + 'markers'}}" [filter]="['!has', 'point_count']" [paint]="{
                                'circle-color': '#11b4da',
                                'circle-radius': 4,
                                'circle-stroke-width': 1,
                                'circle-stroke-color': '#fff'
                            }">
                        </mgl-layer>
                    </mgl-map>
                </ng-template>
            </ngb-panel>
        </ngb-accordion>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
    </div>
</ng-template>