<div class="action-container d-flex">
    <img src="../../assets/images/QuestionMark.svg" class="img-fluid" (click)="open(modalWhat)">
    <button data-toggle="modal" class="bg-primary btn btn-primary btn-lg align-self-center" (click)="open(modalWhat)">What?</button>
</div>
<ng-template #modalWhat let-modal>
    <div class="modal-header">
        <h2 class="modal-title" id="modal-basic-title">Sentiment overview</h2>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <ngb-accordion #acc="ngbAccordion" [closeOthers]="true">
            <ngb-panel>
                <ng-template ngbPanelTitle>Sentiment Analysis</ng-template>
                <ng-template ngbPanelContent>
                    <div *ngFor="let topic of topics" class="mb5">
                        <h3 class="text-center mb-3">{{topic.name}}</h3>
                        <p>{{topic.content.themeAnalysis}}</p>
                    </div>
                </ng-template>
            </ngb-panel>
            <ngb-panel>
                <ng-template ngbPanelTitle>Associated hashtags</ng-template>
                <ng-template ngbPanelContent>
                    <div *ngFor="let topic of topics" class="mb5">
                        <h3 class="text-center mb-3">{{topic.name}}</h3>
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th>Word</th>
                                    <th>Neutral hits</th>
                                    <th>Positive hits</th>
                                    <th>Negative hits</th>
                                    <th>Total hits</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let hashtag of topic.associatedHashtags">
                                    <td>{{hashtag.Item}}</td>
                                    <td>{{hashtag["Neutral Hits"]}}</td>
                                    <td>{{hashtag["Positive Hits"]}}</td>
                                    <td>{{hashtag["Negative Hits"]}}</td>
                                    <td>{{hashtag["Total Hits"]}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </ng-template>
            </ngb-panel>
        </ngb-accordion>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
    </div>
</ng-template>