import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ApiService } from 'src/app/services/api.service';

@Component({
    selector: 'app-individual-profile',
    templateUrl: './individual-profile.component.html',
    styleUrls: ['./individual-profile.component.css']
})
export class IndividualProfileComponent implements OnInit {

    constructor(
        private domSanitizer: DomSanitizer,
        private apiService: ApiService) { }

    depthSelected = false;
    reportDescription: any = '';
    profileButtonText = 'Choose option';
    formatButtonText = 'Choose option';
    telephone = '';
    idPassport = '';
    depth = '';
    format = '';
    // submitIndividualProfile: FormGroup;

    requestFormat: Array<any> = [
        { name: 'Word document' },
        { name: 'Pdf document' }
    ];

    requestDepth: Array<any> = [
        // {
        //     name: 'level 1', description: `<style>
        //         .notice {
        //             background: #fff6bf;
        //             color: #514721;
        //             border: 2px solid #ffd324;
        //         }
        //         .light-blue-bg {
        //             background: #d5edf8;
        //         }
        //         .light-yellow-bg {
        //             background: #fff6bf;
        //         }
        //         .list-group-numbered li {
        //             display: list-item;
        //         }
        //     </style>
        //     <strong>The following details are contained in a Profile: Level 1 report:</strong>
        //     <ol class="list-group list-group-flush list-group-numbered">
        //         <li class="list-group-item light-blue-bg">Personal Details Summary</li>
        //         <li class="list-group-item light-blue-bg">ID Verification (Verify Home Affairs Status)</li>
        //     </ol>
        //     <div class="notice mt-3">
        //         <ul class="list-group list-group-flush">
        //             <li class="list-group-item light-yellow-bg"><strong>Note:</strong> level 1 profiles do not include media information, social media information and link analysis.</li>
        //             <li class="list-group-item light-yellow-bg"><strong>Note:</strong> Searches conducted in terms of the above are related to South African citizens or individuals with South African passports.</li>
        //         </ul>
        //     </div>`
        // },
        {
            name: 'level 2', description: `<style>
                .notice {
                    background: #fff6bf;
                    color: #514721;
                    border: 2px solid #ffd324;
                }
                .light-blue-bg {
                    background: #d5edf8;
                }
                .light-yellow-bg {
                    background: #fff6bf;
                }
                .list-group-numbered li {
                    display: list-item;
                }
            </style>
            <strong>The following details are contained in a Profile: Level 2 report:</strong>
            <ol class="list-group list-group-flush list-group-numbered">
                <li class="list-group-item light-blue-bg">ID and Telephone Trace</li>
                <li class="list-group-item light-blue-bg">Personal Details Summary</li>
                <li class="list-group-item light-blue-bg">ID Verification (Verify Home Affairs Status)</li>
                <li class="list-group-item light-blue-bg">Marital Status</li>
                <li class="list-group-item light-blue-bg">Confirmed Information (Address)</li>
                <li class="list-group-item light-blue-bg">Contact Number History</li>
                <li class="list-group-item light-blue-bg">Telephone Linkage (Home, Cell phone and Work)</li>
                <li class="list-group-item light-blue-bg">Email Address History</li>
                <li class="list-group-item light-blue-bg">ID Authentication Verification</li>
                <li class="list-group-item light-blue-bg">Employment History Information</li>
                <li class="list-group-item light-blue-bg">Judgements/Aministration orders/Default Alerts/Debt Review</li>
                <li class="list-group-item light-blue-bg">Property Interests/deeds enquiry</li>
                <li class="list-group-item light-blue-bg">Disqualified directorship check</li>
                <li class="list-group-item light-blue-bg">Business Interests/Principal membership</li>
                <li class="list-group-item light-blue-bg">Private Security Industry Regulatory Authority (PSIRA) membership authentication</li>
            </ol>
            <div class="notice mt-3">
                <ul class="list-group list-group-flush">
                    <li class="list-group-item light-yellow-bg"><strong>Note:</strong> level 2 profiles do not include media information, social media information and link analysis.</li>
                    <li class="list-group-item light-yellow-bg"><strong>Note:</strong> Searches conducted in terms of the above are related to South African citizens or individuals with South African passports.</li>
                </ul>
            </div>`
        },
        // {
        //     name: 'level 2 - no branding', description: `<style>
        //         .notice {
        //             background: #fff6bf;
        //             color: #514721;
        //             border: 2px solid #ffd324;
        //         }
        //         .light-blue-bg {
        //             background: #d5edf8;
        //         }
        //         .light-yellow-bg {
        //             background: #fff6bf;
        //         }
        //         .list-group-numbered li {
        //             display: list-item;
        //         }
        //     </style>
        //     <strong>The following details are contained in a Profile: Level 2 - no branding report:</strong>
        //     <ol class="list-group list-group-flush list-group-numbered">
        //         <li class="list-group-item light-blue-bg">ID and Telephone Trace</li>
        //         <li class="list-group-item light-blue-bg">Personal Details Summary</li>
        //         <li class="list-group-item light-blue-bg">ID Verification (Verify Home Affairs Status)</li>
        //         <li class="list-group-item light-blue-bg">Marital Status</li>
        //         <li class="list-group-item light-blue-bg">Confirmed Information (Address)</li>
        //         <li class="list-group-item light-blue-bg">Contact Number History</li>
        //         <li class="list-group-item light-blue-bg">Telephone Linkage (Home, Cell phone and Work)</li>
        //         <li class="list-group-item light-blue-bg">Email Address History</li>
        //         <li class="list-group-item light-blue-bg">ID Authentication Verification</li>
        //         <li class="list-group-item light-blue-bg">Employment History Information</li>
        //         <li class="list-group-item light-blue-bg">Judgements/Aministration orders/Default Alerts/Debt Review</li>
        //         <li class="list-group-item light-blue-bg">Property Interests/deeds enquiry</li>
        //         <li class="list-group-item light-blue-bg">Disqualified directorship check</li>
        //         <li class="list-group-item light-blue-bg">Business Interests/Principal membership</li>
        //         <li class="list-group-item light-blue-bg">Private Security Industry Regulatory Authority (PSIRA) membership authentication</li>
        //     </ol>
        //     <div class="notice mt-3">
        //         <ul class="list-group list-group-flush">
        //             <li class="list-group-item light-yellow-bg"><strong>Note:</strong> level 2 profiles do not include media information, social media information and link analysis.</li>
        //             <li class="list-group-item light-yellow-bg"><strong>Note:</strong> Searches conducted in terms of the above are related to South African citizens or individuals with South African passports.</li>
        //         </ul>
        //     </div>`
        // },
        {
            name: 'level 2 - CRDT', description: `<style>
                .notice {
                    background: #fff6bf;
                    color: #514721;
                    border: 2px solid #ffd324;
                }
                .light-blue-bg {
                    background: #d5edf8;
                }
                .light-yellow-bg {
                    background: #fff6bf;
                }
                .list-group-numbered li {
                    display: list-item;
                }
            </style>
            <strong>The following details are contained in a Profile: Level 2 - CRDT report:</strong>
            <ol class="list-group list-group-flush list-group-numbered">
                <li class="list-group-item light-blue-bg">ID and Telephone Trace</li>
                <li class="list-group-item light-blue-bg">Personal Details Summary</li>
                <li class="list-group-item light-blue-bg">ID Verification (Verify Home Affairs Status)</li>
                <li class="list-group-item light-blue-bg">Marital Status</li>
                <li class="list-group-item light-blue-bg">Confirmed Information (Address)</li>
                <li class="list-group-item light-blue-bg">Contact Number History</li>
                <li class="list-group-item light-blue-bg">Telephone Linkage (Home, Cell phone and Work)</li>
                <li class="list-group-item light-blue-bg">Email Address History</li>
                <li class="list-group-item light-blue-bg">ID Authentication Verification</li>
                <li class="list-group-item light-blue-bg">Employment History Information</li>
                <li class="list-group-item light-blue-bg">Payment Profile</li>
                <li class="list-group-item light-blue-bg">Judgements/Aministration orders/Default Alerts/Debt Review</li>
                <li class="list-group-item light-blue-bg">Property Interests/deeds enquiry</li>
                <li class="list-group-item light-blue-bg">Disqualified directorship check</li>
                <li class="list-group-item light-blue-bg">Business Interests/Principal membership</li>
                <li class="list-group-item light-blue-bg">Private Security Industry Regulatory Authority (PSIRA) membership authentication</li>
            </ol>
            <div class="notice mt-3">
                <ul class="list-group list-group-flush">
                    <li class="list-group-item light-yellow-bg"><strong>Note:</strong> level 2 profiles do not include media information, social media information and link analysis.</li>
                    <li class="list-group-item light-yellow-bg"><strong>Note:</strong> Searches conducted in terms of the above are related to South African citizens or individuals with South African passports.</li>
                </ul>
            </div>`
        }
    ];

    ngOnInit(): void {
    }

    setDepth(depth: any): void {
        this.depthSelected = true;
        this.depth = depth;
        for (const depthObj of this.requestDepth) {
            if (depthObj.name === depth) {
                this.reportDescription = this.domSanitizer.bypassSecurityTrustHtml(depthObj.description);
                this.profileButtonText = 'Profile: ' + depth;
            }
        }
    }

    setFormat(format: any): void {
        this.formatButtonText = format;
        this.format = format;
    }

    submitForm(): void {
        this.depth = 'level 2';
        const data: any = {
            depth: this.depth,
            // format: this.format,
            id: this.idPassport,
            // tel: this.telephone,
            type: 'individual'
        };
        this.apiService.generateProfile('person', data).subscribe((resultData: any) => {
            console.log(resultData);
        });

    }

}
