import { Component, OnInit } from '@angular/core';
import { delay } from 'rxjs/operators';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
    title = 'report';
    loading: boolean = false;

    ngOnInit() {
        this.listenToLoading();
    }

    listenToLoading(): void {
        this.loadingService.loadingSub.pipe(delay(0)).subscribe((loading) => {
            this.loading = loading;
        });
    }

    constructor(private loadingService: LoadingService) { }
}
