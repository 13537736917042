<header class="p-3">
    <h1 class="bg-white p-4 text-center rounded-lg shadow">Generate report</h1>
</header>
<div class="container p-3">
    <div *ngIf="loading" class="progress-loader">
        <div class="loading-spinner">
            <img src="../../assets/images/loading.svg">
            <span class="loading-message">Please wait...</span>
        </div>
    </div>
    <div [hidden]="submitted">

        <form #generateReportForm="ngForm">
            <div class="form-group bg-white p-3 m-3 rounded-lg shadow">
                <label for="report-name"><h4>Report Name</h4></label>
                <input type="text" [(ngModel)]="reportName" name="reportName" class="form-control" id="report-name" placeholder="Enter report name here...">
            </div>
            <div class="form-group bg-white p-3 m-3 rounded-lg shadow">
                <label for="topic"><h4>Topic</h4></label>
                <input type="text" class="form-control" [(ngModel)]="topicToAdd" name="topicToAdd" id="topic" aria-describedby="topic-help" placeholder="Enter topic here...">
                <small id="topic-help" class="form-text text-muted">e.g: #RACISTBANKSMUSTFALL</small>
                <label for="topic-analysis">Topic analysis</label>
                <textarea class="form-control" id="topic-analysis" name="topic-analysis" [(ngModel)]="topicToAddAnalysis" placeholder="Enter analysis here..." rows="3"></textarea>
                <button class="btn btn-primary mt-3" (click)="addTopic()">Add</button>
                <table class="table mt-5">
                    <thead>
                        <tr>
                            <th scope="col">Topic</th>
                            <th scope="col">Topic Analysis</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let topic of topics">
                            <td>{{topic.name}}</td>
                            <td>{{topic.themeAnalysis}}</td>
                        </tr>
                    </tbody>
                </table>

            </div>
            <div class="form-group bg-white p-3 m-3 rounded-lg shadow">
                <h3 class="mb-4">Subversive Sentiment</h3>
                <label for="subversive-sentiment-summary"><h4>Summary</h4></label>
                <textarea id="subversive-sentiment-summary" [(ngModel)]="subversiveSentimentSummary" name="subversiveSentimentSummary" class="form-control" rows="6" placeholder="Enter subversive sentiment summary here..."></textarea>
                <label class="mt-4" for="subversive-sentiment-analysis"><h4>Sentiment circulation results: net sentiment analysis</h4></label>
                <textarea id="subversive-sentiment-analysis" class="form-control" [(ngModel)]="netSentimentAnalysis" name="netSentimentAnalysis" rows="6" placeholder="Enter net sentiment summary here..."></textarea>
                <h4 class="mt-4">Platforms utilised</h4>
                <label for="social-platform" class="mt-2 mb-1"><h5>Social platform</h5></label>
                <input type="text" class="form-control" [(ngModel)]="socialPlatformToAdd" name="socialPlatformToAdd" id="social-platform" placeholder="Enter social media platform name (social search)...">
                <label for="percentage" class="mt-3 mb-1"><h5>Percentage</h5></label>
                <input type="number" min="10" max="{{remainingPercentage}}" step="10" class="form-control" [(ngModel)]="socialPlatformToAddPercentage" name="socialPlatformToAddPercentage" id="percentage" placeholder="Enter social media platform percentage (social search)...">
                <button class="btn btn-primary mt-3" (click)="addSocialPlatform()">Add</button>
                <table class="table mt-5">
                    <thead>
                        <tr>
                            <th scope="col">Platform</th>
                            <th scope="col">Percentage</th>
                            <th scope="col">Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let socialPlatform of socialPlatforms">
                            <td>{{socialPlatform.name}}</td>
                            <td>{{socialPlatform.percentage}}</td>
                            <td>
                                <button type="button" class="btn btn-danger btn-sm" aria-label="Close">
                                Delete
                            </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="form-group bg-white p-3 m-3 rounded-lg shadow">
                <h3 class="mb-4">Dissident Demographic</h3>
                <label for="dissident-demographic-summary"><h4>Summary</h4></label>
                <textarea id="dissident-demographic-summary" class="form-control" [(ngModel)]="dissidentDemographicSummary" name="dissidentDemographicSummary" rows="6" placeholder="Enter executive summary here..."></textarea>
            </div>
            <div class="form-group bg-white p-3 m-3 rounded-lg shadow">
                <label for="risk-summary" class="mt-4"><h4>Risk summary</h4></label>
                <textarea id="risk-summary" class="form-control" [(ngModel)]="riskSummary" name="riskSummary" rows="6" placeholder="Enter executive summary here..."></textarea>
            </div>
            <div class="form-group bg-white p-3 m-3 rounded-lg shadow">
                <h3 class="mb-4">Analysis table</h3>
                <label for="analysis-theme" class="mt-3 mb-1 d-block"><h5>Theme</h5></label>
                <input id="analysis-theme" type="text" class="form-control" [(ngModel)]="analysisTableTheme" name="analysisTableTheme" placeholder="Enter theme here...">
                <label for="risk-select" class="mt-3 mb-1 d-block"><h5>Risk rating</h5></label>
                <select class="custom-select" [(ngModel)]="analysisTableRiskRating" name="analysisTableRiskRating" id="risk-select">
                <option selected>Please select a risk rating</option>
                <option value="low-risk">Low risk</option>
                <option value="moderate-risk">Moderate risk</option>
                <option value="substantial-risk">Substantial risk</option>
                <option value="high-risk">High risk</option>
                <option value="very-high-risk">Very high risk</option>
            </select>
                <label for="analysis-traction" class="mt-3 mb-1 d-block"><h5>Traction</h5></label>
                <input id="analysis-traction" type="text" class="form-control" [(ngModel)]="analysisTableTraction" name="analysisTableTraction" placeholder="Enter traction here...">
                <label for="analysis-rhetoric" class="mt-3 mb-1 d-block"><h5>Rhetoric</h5></label>
                <input id="analysis-rhetoric" type="text" class="form-control" [(ngModel)]="analysisTableRhetoric" name="analysisTableRhetoric" placeholder="Enter rhetoric here...">
            </div>
            <div class="form-group bg-white p-3 m-3 rounded-lg shadow">
                <label class="mb-4" for="executive-summary"><h3>Executive summary</h3></label>
                <textarea id="executive-summary" class="form-control" [(ngModel)]="executiveSummary" name="executiveSummary" rows="6" placeholder="Enter executive summary here..."></textarea>
            </div>
            <button (click)="create(generateReportForm)" class="form-control btn btn-primary btn-lg mx-auto d-block shadow mt-5 mb-5" type="submit">Submit</button>
        </form>
    </div>
    <div [hidden]="!submitted">
        <div class="bg-white p-4 text-center rounded-lg shadow">
            <p>Thank you.</p>
            <p>Your report has been generated and can be viewed at: <a href="{{reportLink}}">{{reportLink}}</a></p>
        </div>
    </div>

</div>