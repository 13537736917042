import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class ApiService {
    httpOptions: object = {
        headers: new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded'
        })
    };

    baseUrl = 'http://api.test/';
    // baseUrl = 'https://api.topicworx.co.za/';

    constructor(
        private http: HttpClient
    ) { }

    login(data: object): any {
        const loginUrl = this.baseUrl + 'risk/account/login/';
        return this.http.post<any>(
            loginUrl,
            this.objectToUrlString(data),
            this.httpOptions
        );
    }

    save(data: any): any {
        const strungData = JSON.stringify(data);
        const saveUrl = this.baseUrl + 'risk/report/save/';
        return this.http.post<any>(
            saveUrl, 'data=' + encodeURIComponent(strungData),
            this.httpOptions
        );
    }

    getReport(reportId: string): any {
        const getReportUrl = this.baseUrl + 'risk/report/get/';
        return this.http.get<any>(
            getReportUrl + reportId,
            this.httpOptions
        );
    }

    getReportList(): any {
        const getReportListUrl = this.baseUrl + 'risk/report/list/';
        return this.http.get<any>(
            getReportListUrl, this.httpOptions
        )
    }

    objectToUrlString(object: any): string {
        return Object.entries(object).map(
            ([key]) => `${encodeURIComponent(key)}=${encodeURIComponent(object[key])}`
        ).join('&');
    }

    generateProfile(type: string, details: any): any {
        const profileUrl = this.baseUrl + 'risk/statutory/' + type;
        return this.http.post<any>(
            profileUrl,
            this.objectToUrlString(details),
            this.httpOptions
        );
    }

    getProfileList(type: string): any {
        const profileListUrl = this.baseUrl + 'risk/profile/list/' + type;
        return this.http.get<any>(profileListUrl, this.httpOptions);
    }

    getProfileDetails(category: string, id: number) {
        const profileDetailsUrl = this.baseUrl + 'risk/profile/' + category + '/' + id + '/';
        return this.http.get<any>(profileDetailsUrl, this.httpOptions);
    }

    updateNodeDetails(data: object) {
        const nodeUpdateDetailsUrl = this.baseUrl + 'risk/update/node/';
        return this.http.post<any>(nodeUpdateDetailsUrl, this.objectToUrlString(data), this.httpOptions);
    }

    addNewNode(data: object) {
        let stringData = JSON.stringify(data);
        const nodeAddUrl = this.baseUrl + 'risk/add/node/';
        return this.http.post<any>(nodeAddUrl, 'data=' + encodeURIComponent(stringData), this.httpOptions);
    }

}
