import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ProcessDataService {

  constructor() { }

    processFlexibleData(testField: string, itemData: any): any {
        let details: any = {};
        if (Object.keys(itemData).length > 0) {
            let items: Array<any> = [];
            if (itemData.hasOwnProperty(testField)) {
                details.nodeId = itemData.nodeId;
                if (itemData.hasOwnProperty('Notes')) {
                    details.Notes = { content: itemData.Notes['General Overview'].data, dataId: itemData.Notes['General Overview'].id }
                }
                let item = this.processFlexibleDataData(itemData);
                items.push(item);
            } else {
                for (let detailName in itemData) {
                    switch (detailName) {
                        case 'nodeId':
                            details[detailName] = itemData[detailName];
                            break;
                        case 'Notes':
                            details[detailName] = { content: itemData[detailName]['General Overview'].data, dataId: itemData[detailName]['General Overview'].id };
                            break;
                        default:
                            if (itemData[detailName].hasOwnProperty('Details')) {
                                details.details = itemData[detailName].Details.data;
                            } else {
                                let item: any = {};
                                for (let itemName in itemData[detailName]) {
                                    item[itemName] = itemData[detailName][itemName].data;
                                }
                                items.push(item);
                            }
                            break;
                    }
                }
            }
            details.items = items;
        }
        return details;
    }

    processFlexibleDataData(itemData: any): any {
        let details: any = {};
        for (let detailName in itemData) {
            details[detailName] = itemData[detailName].data;
            // switch (detailName) {
            //     case 'nodeId':
            //         details[detailName] = itemData[detailName];
            //         break;
            //     case 'Notes':
            //         details[detailName] = { content: itemData[detailName]['General Overview'].data, dataId: itemData[detailName]['General Overview'].id }
            //         break;
            //     default:
            //         break;
            // }
        }
        return details;
    }

    processMultiLevelData(itemData: any): any {
        let details: any = {};
        let items: Array<any> = [];
        for (let detailName in itemData) {
            switch (detailName) {
                case 'nodeId':
                    details[detailName] = itemData[detailName];
                    break;
                case 'Notes':
                    details[detailName] = { content: itemData[detailName]['General Overview'].data, dataId: itemData[detailName]['General Overview'].id }
                    break;
                default:
                    if (itemData[detailName].hasOwnProperty('Details')) {
                        details.details = itemData[detailName].Details.data;
                    } else {
                        let item: any = {};
                        for (let itemName in itemData[detailName]) {
                            switch (itemName) {
                                case 'nodeId':
                                    item[itemName] = itemData[detailName][itemName];
                                    break;
                                case 'Notes':
                                    item[itemName] = { content: itemData[detailName][itemName]['General Overview'].data, dataId: itemData[detailName][itemName]['General Overview'].id }
                                    break;
                                default:
                                    item[itemName] = itemData[detailName][itemName].data;
                                    break;
                            }
                        }
                        items.push(item);
                    }
                    break;
            }
        }
        details.items = items;
        return details;
    }
    processDirectorshipData(itemData: any): any {
        let details: any = {};
        if (Object.keys(itemData).length > 2) {
            let items: Array<any> = [];
            for (let detailName in itemData) {
                switch (detailName) {
                    case 'nodeId':
                        details[detailName] = itemData[detailName];
                        break;
                    case 'Notes':
                        details[detailName] = { content: itemData[detailName]['General Overview'].data, dataId: itemData[detailName]['General Overview'].id }
                        break;
                    default:
                        if (itemData[detailName].hasOwnProperty('Details')) {
                            details.details = itemData[detailName].Details.data;
                        } else {
                            let item: any = {};
                            for (let itemName in itemData[detailName]) {
                                switch (itemName) {
                                    case 'nodeId':
                                        item[itemName] = itemData[detailName][itemName];
                                        break;
                                    case 'Notes':
                                        item[itemName] = { content: itemData[detailName][itemName]['General Overview'].data, dataId: itemData[detailName][itemName]['General Overview'].id }
                                        break;
                                    case 'PhysicalAddress':
                                        item[itemName] = { content: itemData[detailName][itemName].data, dataId: itemData[detailName][itemName].id }
                                        break;
                                    default:
                                        // item[itemName] = { content: itemData[detailName][itemName].data, dataId: itemData[detailName][itemName].id }
                                        item[itemName] = itemData[detailName][itemName].data;
                                        break;
                                }
                            }
                            items.push(item);
                        }
                        break;
                }
                // if (detailName !== 'nodeId') {
                //     if (itemData[detailName].hasOwnProperty('Details')) {
                //         details.details = itemData[detailName].Details.data;
                //     } else {
                //         let item: any = {};
                //         for (let itemName in itemData[detailName]) {
                //             item[itemName] = itemData[detailName][itemName].data;
                //         }
                //         items.push(item);
                //     }
                // }
            }
            details.items = items;
        } else {
            details.details = itemData['Details'].data;
        }
        return details;
    }

    processAddressData(itemData: any): any {
        let details: any = {};
        let items: Array<any> = [];
        for (let detailName in itemData) {
            if (detailName !== 'nodeId') {
                if (itemData[detailName].hasOwnProperty('Details')) {
                    details.details = itemData[detailName].Details.data;
                } else {
                    let item: any = {};
                    for (let itemName in itemData[detailName]) {
                        item[itemName] = itemData[detailName][itemName].data;
                    }
                    items.push(item);
                }
            }
        }
        details.items = items;
        return details;


    }

    processData(itemData: any): any {
        let details: any = {};
        for (let detailName in itemData) {
            switch (detailName) {
                case 'nodeId':
                    details[detailName] = itemData[detailName];
                    break;
                case 'Notes':
                    details[detailName] = { content: itemData[detailName]['General Overview'].data, dataId: itemData[detailName]['General Overview'].id }
                    break;
                default:
                    details[detailName] = itemData[detailName].data;
                    break;
            }
        }
        return details;
    }

    processEmployerData(itemData: any): any {
        let details: any = {};
        let items: Array<any> = [];
        for (let detailName in itemData) {
            if (detailName !== 'nodeId') {
                if (itemData[detailName].hasOwnProperty('Details')) {
                    details.details = itemData[detailName].Details.data;
                } else {
                    let item: any = {};
                    for (let itemName in itemData[detailName]) {
                        item[itemName] = itemData[detailName][itemName].data;
                    }
                    items.push(item);
                }
            }
        }
        details.items = items;
        return details;
        // let details: Array<any> = [];
        // // let details: any = {};
        // let items: Array<any> = [];
        // for (let detailName in itemData) {
        //     if (detailName !== 'nodeId') {
        //         details.push({ name: itemData[detailName].EmployerDetail.data, date: itemData[detailName].LastUpdatedDate.data });
        //     }
        // }
        // return details;
    }

    processFinancialData(itemData: any): any {
        let details: any = {};
        let items: Array<any> = [];
        for (let detailName in itemData) {
            switch (detailName) {
                case 'nodeId':
                    details[detailName] = itemData[detailName];
                    break;
                case 'Notes':
                    details[detailName] = { content: itemData[detailName]['General Overview'].data, dataId: itemData[detailName]['General Overview'].id }
                    break;
                default:
                    items.push(this.processData(itemData[detailName]));
                    break;
            }
        }
        details.items = items;
        return details;
    }

    // processFinancialData(itemData: any): any {
    //     let details: Array<any> = [];
    //     // let financialRecords: Array<any> = [];
    //     for (let detailName in itemData) {
    //         if (detailName !== 'nodeId') {
    //             let dataObject: any = {};
    //             if (itemData[detailName].hasOwnProperty('style')) {
    //                 dataObject = { name: itemData[detailName].type.data, data: itemData[detailName].value.data, style: itemData[detailName].style.data };
    //                 // financialRecords.push(itemData[detailName].type.data);
    //             } else {
    //                 dataObject = { name: itemData[detailName].type.data, data: itemData[detailName].value.data };
    //             }
    //             details.push(dataObject);
    //         }
    //     }
    //     // if (financialRecords.length > 0) {
    //     //     this.populateFinancialRecords(financialRecords);
    //     // }
    //     return details;
    // }

    // populateFinancialRecords(records: Array<any>): void {
    //     for (let record of records) {
    //         switch (record) {
    //             case 'Debt review':
    //                 let tempObj = this.processFinancialRecordDetails('Debt review', this.profile.ConsumerDebtReviewStatus);
    //                 // tempObj.name = 'Debt Review';
    //                 this.financialRecords.push(tempObj);
    //         }
    //     }
    // }
    processFinancialRecordDetails(title: string, itemData: any): any {
        let details: any = {}
        let tempData: Array<any> = [];
        for (let detailName in itemData) {
            switch (detailName) {
                case 'nodeId':
                    details.nodeId = itemData.nodeId;
                    break;
                case 'Notes':
                    details.Notes = { content: itemData[detailName]['General Overview'].data, dataId: itemData[detailName]['General Overview'].id };
                    break;
                default:
                    tempData.push({ name: detailName, data: itemData[detailName].data });
                    break;
            }
        }
        if (tempData.length > 0) {
            details.title = title;
            details.data = tempData;
        }
        return details;
    }
}
