import { Component, OnInit, Input } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-what',
    templateUrl: './what.component.html',
    styleUrls: ['./what.component.css']
})
export class WhatComponent implements OnInit {
    closeResult = '';
    @Input() topics: any;

    constructor(
        private modal: NgbModal,
    ) { }

    ngOnInit(): void {
    }

    open(content: any): void {
        this.modal.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'xl', scrollable: true }).result.then((result) => {
            this.closeResult = 'Closed with: ' + result;
        }, (reason) => {
            this.closeResult = 'Dismissed ' + this.getDismissedReason(reason);
        });
    }

    private getDismissedReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return 'with ' + reason;
        }
    }

}
