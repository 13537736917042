<div class="action-container d-flex">
    <img src="../../assets/images/When.svg" class="img-fluid" (click)="open(modalWhen)">
    <button data-toggle="modal" class="bg-primary btn btn-primary btn-lg align-self-center" (click)="open(modalWhen)">When?</button>
</div>
<ng-template #modalWhen let-modal>
    <div class="modal-header">
        <h2 class="modal-title" id="modal-basic-title">SUBVERSIVE SENTIMENT</h2>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
    </div>
    <div class="modal-body accordion-sentiment">
        <div class="">
            <h5>SUMMARY</h5>
            <p>
                {{subversiveSentimentSummary}}
            </p>
        </div>
        <ngb-accordion #acc="ngbAccordion" [closeOthers]="true">
            <ngb-panel>
                <ng-template ngbPanelTitle>Results over time</ng-template>
                <ng-template ngbPanelContent>
                    <ngx-charts-line-chart [curve]="resultsOverTimeChart.curve" [view]="resultsOverTimeChart.view" [scheme]="resultsOverTimeChart.colorScheme" [legend]="resultsOverTimeChart.legend" [showXAxisLabel]="resultsOverTimeChart.showXAxisLabel" [showYAxisLabel]="resultsOverTimeChart.showYAxisLabel"
                        [xAxis]="resultsOverTimeChart.xAxis" [yAxis]="resultsOverTimeChart.yAxis" [xAxisLabel]="resultsOverTimeChart.xAxisLabel" [yAxisLabel]="resultsOverTimeChart.yAxisLabel" [timeline]="resultsOverTimeChart.timeline" [results]="resultsOverTimeChart.results">
                    </ngx-charts-line-chart>
                    <!-- <ngx-charts-line-chart [view]="resultsOverTimeChart.view" [scheme]="resultsOverTimeChart.colorScheme" [legend]="resultsOverTimeChart.legend" [showXAxisLabel]="resultsOverTimeChart.showXAxisLabel" [showYAxisLabel]="resultsOverTimeChart.showYAxisLabel"
                        [xAxis]="resultsOverTimeChart.xAxis" [yAxis]="resultsOverTimeChart.yAxis" [xAxisLabel]="resultsOverTimeChart.xAxisLabel" [yAxisLabel]="resultsOverTimeChart.yAxisLabel" [timeline]="resultsOverTimeChart.timeline" [results]="resultsOverTimeChart.results"
                        (select)="onSelect($event)" (activate)="onActivate($event)" (deactivate)="onDeactivate($event)">
                    </ngx-charts-line-chart> -->

                    <!-- <ngx-charts-line-chart [view]="sentimentLineChart.view" [scheme]="sentimentLineChart.colorScheme" [legend]="sentimentLineChart.legend" [showXAxisLabel]="sentimentLineChart.showXAxisLabel" [showYAxisLabel]="sentimentLineChart.showYAxisLabel" [xAxis]="sentimentLineChart.xAxis"
                            [yAxis]="sentimentLineChart.yAxis" [xAxisLabel]="sentimentLineChart.xAxisLabel" [yAxisLabel]="sentimentLineChart.yAxisLabel" [timeline]="sentimentLineChart.timeline" [results]="sentimentLineChart.data" (select)="onSelect($event)"
                            (activate)="onActivate($event)" (deactivate)="onDeactivate($event)"></ngx-charts-line-chart> -->
                </ng-template>
            </ngb-panel>
            <ngb-panel>
                <ng-template ngbPanelTitle>Net sentiment over time</ng-template>
                <ng-template ngbPanelContent>

                    <ngx-charts-line-chart [curve]="netSentimentOverTimeChart.curve" [view]="netSentimentOverTimeChart.view" [scheme]="netSentimentOverTimeChart.colorScheme" [legend]="netSentimentOverTimeChart.legend" [showXAxisLabel]="netSentimentOverTimeChart.showXAxisLabel"
                        [showYAxisLabel]="netSentimentOverTimeChart.showYAxisLabel" [xAxis]="netSentimentOverTimeChart.xAxis" [yAxis]="netSentimentOverTimeChart.yAxis" [xAxisLabel]="netSentimentOverTimeChart.xAxisLabel" [yAxisLabel]="netSentimentOverTimeChart.yAxisLabel"
                        [timeline]="netSentimentOverTimeChart.timeline" [results]="netSentimentOverTimeChart.results">
                    </ngx-charts-line-chart>
                    <!-- <div class="mt-3">
                        <ngx-charts-bar-vertical-2d *ngIf="sentimentChart" [view]="sentimentChart.view" [scheme]="sentimentChart.colorScheme" [results]="sentimentChart.data" [gradient]="sentimentChart.gradient" [xAxis]="sentimentChart.showXAxis" [yAxis]="sentimentChart.showYAxis"
                                [legend]="sentimentChart.showLegend" [showXAxisLabel]="sentimentChart.showXAxisLabel" [showYAxisLabel]="sentimentChart.showYAxisLabel" [xAxisLabel]="sentimentChart.xAxisLabel" [yAxisLabel]="sentimentChart.yAxisLabel" (select)="onSelect($event)">
                            </ngx-charts-bar-vertical-2d>
                    </div> -->
                </ng-template>
            </ngb-panel>
        </ngb-accordion>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
    </div>
</ng-template>