<ng-template #modalNewNode let-modal>
    <div class="modal-header">
        <h2 class="modal-title" id="modal-basic-title">{{newNodeTitle}}</h2>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.close('cancel')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <quill-editor [(ngModel)]="newNodeContent" class="content-edit mt-2"></quill-editor>
    </div>
    <div class="modal-footer">
        <button class="btn btn-primary content-new-submit me-3" (click)="modal.close('save')">Save</button>
        <button class="btn btn-danger content-new-cancel" (click)="modal.close('cancel')">Cancel</button>
    </div>
</ng-template>