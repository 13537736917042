<div class="row">
    <div class="col-5">
        <img class="img-fluid" src="../../assets/images/undraw_performance_overview_p9bm.svg">
    </div>
    <div class="col-7">
        <h1>{{title}}</h1>
        <h4 class="font-italic">Theme: {{theme}}</h4>
        <h4>Date: {{date}}</h4>
        <h4>Risk: {{riskRating}}</h4>
        <h4>Traction: {{traction}}</h4>
        <h4>Rhetoric: {{rhetoric}}</h4>
        <h4>Executive summary</h4>
        <div>{{executiveSummary}}</div>
    </div>
</div>