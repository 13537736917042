import { Component, OnInit, OnChanges, Input, Output, EventEmitter, ViewChild, ElementRef, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/services/api.service';
import { NodeService } from 'src/app/services/node.service';
import { ToastService } from 'src/app/services/toast.service';
@Component({
    selector: 'app-data-new-modal',
    templateUrl: './data-new-modal.component.html',
    styleUrls: ['./data-new-modal.component.css']
})
export class DataNewModalComponent implements OnInit {

    // newNodeTitle: string = '';
    newNodeContent: string = '';
    closeResult = '';

    currentRouter = this.router.url;

    @Input() newNode: string = 'false';
    @Input() newNodeTitle: string = '';

    @Output() nodeEnd = new EventEmitter<boolean>();
    @Output() newContent = new EventEmitter<any>();


    @ViewChild('modalNewNode') modalDom: ElementRef<any> | undefined;

    constructor(
        private modal: NgbModal,
        private apiService: ApiService,
        private toastService: ToastService,
        private nodeService: NodeService,
        private router: Router
    ) { }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.newNode.currentValue === 'true') {
            this.open(this.modalDom);
        }
    }


    open(content: any) {
        this.modal.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'xl', scrollable: true }).result.then((result) => {
            console.log(result);
            this.nodeEnd.emit(true);
            if (result === 'save') {
                this.saveNewNode();
            }
            this.newNodeTitle = '';
            this.newNodeContent = '';
            this.newNode = 'false';
            this.closeResult = 'Closed with: ' + result;
        }, (reason) => {
            this.closeResult = 'Dismissed ' + this.getDismissedReason(reason);
        })
    }

    saveNewNode(): void {
        let newContent: string = this.newNodeContent;
        this.apiService.addNewNode(
            {
                "parent": this.nodeService.id,
                "data": {
                    "title": this.newNodeTitle,
                    "content": this.newNodeContent
                }
            }
        ).subscribe(
            resultData => {
                if (resultData.success) {
                    this.newContent.emit({
                        content: newContent,
                        dataId: resultData.data.dataId
                    });
                    console.log(resultData);
                    console.log(newContent);
                    this.toastService.show('New node successfully added.', { classname: 'bg-success text-light zoom-120', delay: 5000 });
                    this.newNodeTitle = '';
                    this.newNodeContent = '';
                    this.newNode = 'false';

                    this.router.navigate([this.currentRouter]);
                } else {
                    this.toastService.show('We could not add a new node at this time.', { classname: 'bg-danger text-light zoom-120', delay: 5000 });
                }
            }
        );
    }
    cancelNew() {
        this.newNodeTitle = '';
        this.newNodeContent = '';
        this.newNode = 'false';
    }

    private getDismissedReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return 'with ' + reason;
        }
    }

}
