import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-dissident-demographic',
    templateUrl: './dissident-demographic.component.html',
    styleUrls: ['./dissident-demographic.component.css']
})
export class DissidentDemographicComponent implements OnInit {
    @Input() dissidentDemographicSummary: any;
    @Input() topics: any;

    genderBarChartTemplate: any = {
        view: [700, 150],
        results: [],
        legend: true,
        legendTitle: 'Gender',
        xAxis: true,
        yAxis: true,
        xAxisLabel: 'Gender',
        yAxisLabel: 'Count',
        colourScheme: 'vivid'
    };

    ageBarChartTemplate: any = {

        view: [700, 200],
        results: [],
        legend: true,
        legendTitle: 'Age group',
        xAxis: true,
        yAxis: true,
        xAxisLabel: 'Age',
        yAxisLabel: 'Count',
        colourScheme: 'vivid'
    };

    constructor() { }

    ngOnInit(): void {
        this.setupGenderBarCharts();
        this.setupAgeBarCharts();
    }

    setupGenderBarCharts(): void {
        for (const topic of this.topics) {
            const genderData = [
                {
                    name: 'Female',
                    value: topic.content.gender[0].Female
                },
                {
                    name: 'Male',
                    value: topic.content.gender[0].Male
                },
            ];
            topic.genderData = genderData;
        }
    }

    setupAgeBarCharts(): void {
        for (const topic of this.topics) {
            const ageData = [];
            for (const ageBand of topic.content.age) {
                ageData.push({
                    name: ageBand.Labels,
                    value: ageBand.Results
                });
            }
            topic.ageData = ageData;
        }
    }

}
