<div class="container">
    <div *ngIf="loading" class="progress-loader">
        <div class="loading-spinner">
            <img src="../../assets/images/loading.svg">
            <span class="loading-message bg-white p-3 m-3 rounded-lg shadow">Please wait...</span>
        </div>
    </div>
    <div class="bg-white p-3 m-3 rounded-lg shadow">
        <app-summary [title]="title" [theme]="theme" [date]="date" [riskRating]="riskRating" [traction]="traction" [rhetoric]="rhetoric" [executiveSummary]="executiveSummary"></app-summary>
    </div>
    <div class="bg-white p-3 m-3 rounded-lg shadow">
        <div class="row px-n3">
            <div class="col">
                <app-what [topics]="topics"></app-what>
            </div>
            <div class="col">
                <app-who [topics]="topics"></app-who>
            </div>
            <div class="col">
                <app-where [topics]="topics"></app-where>
            </div>
            <div class="col">
                <app-when [topics]="topics" [subversiveSentimentSummary]="subversiveSentimentSummary" [netSentimentOverTimeChartData]="netSentimentOverTimeChartData" [resultsOverTimeChartData]="resultsOverTimeChartData"></app-when>
            </div>
        </div>
    </div>
    <div *ngIf="dissidentDemographicSummary" class="bg-white p-3 m-3 rounded-lg shadow">
        <app-dissident-demographic [topics]="topics" [dissidentDemographicSummary]="dissidentDemographicSummary"></app-dissident-demographic>
    </div>
    <div *ngIf="riskSummary" class="bg-white p-3 m-3 rounded-lg shadow">
        <app-risk-summary [riskSummary]="riskSummary"></app-risk-summary>
    </div>
    <div class="bg-white p-3 m-3 rounded-lg shadow">
        The information in this document is confidential and is legally privileged. It is intended solely for the addressee/s. Access to this document by anyone other than the addressee/s is unauthorised. If you are not the intended recipient, any disclosure,
        copying, distribution, or action taken or omitted in reliance on it is prohibited and may be unlawful. While all reasonable steps are taken to ensure the accuracy and the integrity of information and data transmitted electronically and to preserve
        the confidentiality thereof, no liability or responsibility whatsoever is accepted by us should information or data for whatever reason or cause be corrupted or fail to reach its intended destination.

    </div>
</div>