<header class="p-3">
    <h1 class="bg-white p-4 text-center rounded-lg shadow">Menu</h1>
</header>
<div class="container p-3">
    <nav class="bg-white p-3 m-3 rounded-lg shadow d-flex justify-content-between">
        <app-back></app-back>
        <app-logout></app-logout>
    </nav>
    <div class="form-group bg-white p-3 m-3 rounded-lg shadow">
        <nav>
            <ul class="list-group">
                <li class="list-group-item">
                    <a routerLink="/generate" routerLinkActive="active">Generate a social media report</a>
                </li>
                <li class="list-group-item">
                    <a routerLink="/exising-reports-social" routerLinkActive="active">View previous social media reports</a>
                </li>
            </ul>
        </nav>
    </div>
</div>