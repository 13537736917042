import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/services/api.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
    selector: 'app-data-edit-modal',
    templateUrl: './data-edit-modal.component.html',
    styleUrls: ['./data-edit-modal.component.css']
})
export class DataEditModalComponent implements OnInit {

    node: any = { title: '' };
    modalEditData: any;

    closeResult = '';

    currentRouter = this.router.url;

    @Input() editorContent: any = {};
    @Input() contentId: string = '';
    @Input() contentTitle: string = '';
    @Input() editNode: string = '';

    @Output() nodeEditEnd = new EventEmitter<boolean>();
    @Output() updatedContent = new EventEmitter<string>();

    @ViewChild('modalEditData') modalDom: ElementRef<any> | undefined;


    constructor(
        private modal: NgbModal,
        private apiService: ApiService,
        private toastService: ToastService,
        private router: Router
    ) { }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges): void {
        // console.log(changes);
        if (changes.editNode.currentValue == 'true') {
            this.editorContent = changes.editorContent.currentValue;
            this.contentId = changes.contentId.currentValue;
            this.contentTitle = changes.contentTitle.currentValue;
            this.open(this.modalDom);
        }
    }

    cancelEdit() {
        this.nodeEditEnd.emit(true);
    }

    open(content: any) {
        this.modal.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'xl', scrollable: true }).result.then((result) => {
            this.nodeEditEnd.emit(true);
            if (result === 'save') {
                this.save();
            }
            this.closeResult = 'Closed with: ' + result;
        }, (reason) => {
            this.closeResult = 'Dismissed ' + this.getDismissedReason(reason);
        })
    }

    save() {
        let newEditorContent = this.editorContent;
        this.apiService.updateNodeDetails({ "id": this.contentId, "data": this.editorContent }).subscribe(resultData => {
            if (resultData.success) {
                this.updatedContent.emit(newEditorContent);
                this.toastService.show('Data successfully updated.', { classname: 'bg-success text-light zoom-120', delay: 5000 });
                // console.log(this.currentRouter);
                // this.router.navigate([this.currentRouter]);
            } else {
                this.toastService.show('We could not update the data at this time.', { classname: 'bg-danger text-light zoom-120', delay: 5000 });
            }
        });
    }

    private getDismissedReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return 'with ' + reason;
        }
    }

}
