import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class NodeService {
    id: any;
    newNode: boolean = false;

    constructor() { }
}
