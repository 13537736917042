<header class="p-3">
    <h1 class="bg-white p-4 text-center rounded-lg shadow">Log in</h1>
</header>
<div class="container">
    <form class="mt-5" id="login-form" #loginForm="ngForm">
        <div class="form-group bg-white p-3 m-3 rounded-lg shadow">
            <label for="email" class="mt-3 mb-1 d-block"><h5>Email</h5></label>
            <input [(ngModel)]="formData.email" #email="ngModel" type="email" class="form-control" id="email" name="email" required autocomplete="email" placeholder="email address">
            <div *ngIf="email.invalid && (email.dirty || email.touched)" class="alert alert-danger alert-dismissible" role="alert">
                Email is required.
            </div>
            <label for="password" class="mt-3 mb-1 d-block"><h5>Password</h5></label>
            <input [(ngModel)]="formData.password" #password="ngModel" type="password" class="form-control" id="password" name="password" required autocomplete="current-password" placeholder="password">
            <div *ngIf="password.invalid && (password.dirty || password.touched)" class="alert alert-danger alert-dismissible" role="alert">
                Password is required.
            </div>
            <button type="submit" class="btn btn-primary mt-4 mx-auto d-block" [disabled]="!loginForm.valid" (click)="login()">Submit</button>
        </div>
    </form>
</div>