import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { ProfileService } from 'src/app/services/profile.service';
import { Location } from '@angular/common';
import { NodeService } from 'src/app/services/node.service';
import { ProcessDataService } from 'src/app/services/process-data.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-profile-edit',
    templateUrl: './profile-edit.component.html',
    styleUrls: ['./profile-edit.component.css']
})
export class ProfileEditComponent implements OnInit {

    profile: { [key: string]: { [key: string]: { [key: string]: { [key: string]: string } } } } = {};
    // profile: any;
    // consumerDetails: {[key: string]:string} = {};
    parentId: number = 0;
    consumerDetails: any;
    fraudIndicators: any;
    addressHistory: any;
    contactNumberHistory: any;
    telephoneLinkageHome: any;
    telephoneLinkageWork: any;
    telephoneLinkageCellular: any;
    emailAddressHistory: any;
    employerDetails: any;
    financialDetails: any;
    financialRecords: Array<any> = [];
    directorshipRecords: any = {};
    disqualifiedDirectorshipRecords: string = '';
    propertyOwnershipRecords: any = {};
    psiraData: any;
    adverseInfo: any;
    judgment: any;
    debtReview: any;

    newNode: string = 'false';
    newNodeTitle: string = '';

    backgroundInformation: any = {};
    conclusion: any = {};
    mediaSearch: any = {};
    socialMedia: any = {};
    mediaArticles: any = {};
    linkAnalysis: any = {};
    editNode: string = 'false';
    editorContent: string = '';
    contentId: number = 0;
    contentTitle: string = '';

    currentContentElement: any;
    // currentNewDataContainer: any = {reference: null};
    currentNewDataContainer: any = { type: '', data: null };

    currentRouter = this.router.url;

    constructor(
        private profileService: ProfileService,
        private apiService: ApiService,
        private processDataService: ProcessDataService,
        private nodeService: NodeService,
        private location: Location,
        private router: Router
    ) { }


    addContent(container: any, title: string, parentId: number): void {
        this.currentNewDataContainer.data = container;
        this.currentNewDataContainer.type = 'content';
        this.newNode = 'true';
        this.newNodeTitle = title;
        this.nodeService.id = parentId;

    }

    updateContent(newContent: any) {
        this.currentContentElement.innerHTML = newContent;
        this.currentContentElement = null;

    }

    addNewContent(newContent: any) {
        if (this.currentNewDataContainer.type === 'content') {
            this.currentNewDataContainer.data.content = newContent.content;
            this.currentNewDataContainer.data.dataId = newContent.dataId;
        } else if (this.currentNewDataContainer.type === 'note') {
            this.currentNewDataContainer.data.Notes = newContent;
        }
        this.currentNewDataContainer = { type: '', data: null };

    }

    newNodeClosed(): void {
        this.newNode = 'false';
    }

    addNote(container: any, parentId: number): void {
        this.currentNewDataContainer.data = container;
        this.currentNewDataContainer.type = 'note';
        this.newNode = 'true';
        this.newNodeTitle = 'Notes';
        this.nodeService.id = parentId;
    }

    editAddress(nodeId: number, $event: any): void {
        let buttonElement: any = $event.target;
        let buttonContainer: any = buttonElement.parentElement;
        this.editorContent = buttonContainer.firstChild.textContent;
        this.contentId = nodeId;
        this.contentTitle = 'Address edit';
        this.editNode = 'true';
    }

    editNote(nodeId: number, $event: any): void {
        let buttonElement: any = $event.target;
        let buttonContainer: any = buttonElement.parentElement;
        let title: string = buttonContainer.parentElement.firstChild.innerText;
        let contentElement: any = buttonContainer.parentElement.children[1].children[1];
        this.currentContentElement = contentElement;
        this.editorContent = (contentElement.innerHTML == '' ? ' ' : contentElement.innerHTML);
        this.contentId = nodeId;
        this.contentTitle = title;
        this.editNode = 'true';
    }

    setupEditableVariables(data: any) {
        this.backgroundInformation = (data['Background Information']) ? {
            content: data['Background Information']['General Overview'].data,
            dataId: data['Background Information']['General Overview'].id
        } : {};
        this.conclusion = (data.Conclusion) ? {
            content: data.Conclusion['General Overview'].data,
            dataId: data.Conclusion['General Overview'].id
        } : {};
        this.mediaSearch = (data['Media Search']) ? {
            content: data['Media Search']['General Overview'].data,
            dataId: data['Media Search']['General Overview'].id
        } : {};
        this.socialMedia = (data['Social Media']) ? {
            content: data['Social Media']['General Overview'].data,
            dataId: data['Social Media']['General Overview'].id
        } : {};
        this.mediaArticles = (data['Media Articles']) ? {
            content: data['Media Articles']['General Overview'].data,
            dataId: data['Media Articles']['General Overview'].id
        } : {};
        this.linkAnalysis = (data['Link Analysis']) ? {
            content: data['Link Analysis']['General Overview'].data,
            dataId: data['Link Analysis']['General Overview'].id
        } : {};
    }

    editContent(nodeId: number, $event: any): void {
        let buttonElement: any = $event.target;
        let buttonContainer: any = buttonElement.parentElement;
        let title: string = buttonContainer.parentElement.firstChild.innerText;
        let contentElement: any = buttonContainer.parentElement.children[1].children[1];
        this.currentContentElement = contentElement;
        this.editorContent = (contentElement.innerHTML == '' ? ' ' : contentElement.innerHTML);
        this.contentId = nodeId;
        this.contentTitle = title;
        this.editNode = 'true';
    }

    closeEdit(): void {
        this.editorContent = '';
        this.contentId = 0;
        this.contentTitle = '';
        this.editNode = 'false';

        this.router.navigate([this.currentRouter]);
    }

    ngOnInit(): void {
        if (typeof this.profileService.id !== 'undefined') {
            this.apiService.getProfileDetails('individual', this.profileService.id).subscribe((resultData: any) => {
                this.parentId = this.profileService.id;
                this.setupEditableVariables(resultData.data.data);

                this.consumerDetails = this.processDataService.processData(resultData.data.data.ConsumerDetail);
                this.fraudIndicators = this.processDataService.processData(resultData.data.data.ConsumerFraudIndicatorsSummary);

                this.addressHistory = this.processDataService.processMultiLevelData(resultData.data.data.ConsumerAddressHistory);
                this.contactNumberHistory = this.processDataService.processMultiLevelData(resultData.data.data.ConsumerTelephoneHistory);
                this.propertyOwnershipRecords = this.processDataService.processMultiLevelData(resultData.data.data.ConsumerPropertyInformation);
                this.psiraData = this.processDataService.processMultiLevelData(resultData.data.data.ConsumerPSIRA)
                this.employerDetails = this.processDataService.processMultiLevelData(resultData.data.data.ConsumerEmploymentHistory);

                this.telephoneLinkageHome = this.processDataService.processFlexibleData('IDNo', resultData.data.data.ConsumerTelephoneLinkageHome);
                this.telephoneLinkageWork = this.processDataService.processFlexibleData('IDNo', resultData.data.data.ConsumerTelephoneLinkageWork);
                this.telephoneLinkageCellular = this.processDataService.processFlexibleData('IDNo', resultData.data.data.ConsumerTelephoneLinkageCellular);
                this.emailAddressHistory = this.processDataService.processFlexibleData('EmailAddress', resultData.data.data.ConsumerEmailHistory);

                this.financialDetails = this.processDataService.processFinancialData(resultData.data.data.ConsumerFinancialRecords);

                this.adverseInfo = this.processDataService.processFinancialRecordDetails('Adverse Information', resultData.data.data.ConsumerAdverseInfo);
                this.debtReview = this.processDataService.processFinancialRecordDetails('Debt review', resultData.data.data.ConsumerDebtReviewStatus);
                this.judgment = this.processDataService.processFinancialRecordDetails('Judgements', resultData.data.data.ConsumerJudgement);

                this.directorshipRecords = this.processDataService.processDirectorshipData(resultData.data.data.ConsumerDirectorShipLink);

                this.disqualifiedDirectorshipRecords = resultData.data.data.ConsumerDisqualifiedDirectorShipLink.Details.data;
            });
        } else {
            this.location.back();
        }
    }


}
