import { Component, OnInit, Input } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-where',
    templateUrl: './where.component.html',
    styleUrls: ['./where.component.css']
})
export class WhereComponent implements OnInit {
    closeResult = '';
    @Input() topics: any;

    whereMap: any = {
        // style: 'mapbox://styles/mapbox/streets-v11',
        style: 'mapbox://styles/mapbox/light-v10',
        zoom: [3],
        center: [24.6727135, -28.4792625],
        // center: [-103.59179687498357, 40.66995747013945],
        layer: {
            id: 'location',
            type: 'line',
            source: 'states',
            paint: {
                'line-color': '#627BC1',
                'line-width': 2
            }
        }

    };

    constructor(
        private modal: NgbModal
    ) { }

    ngOnInit(): void {
    }

    open(content: any): void {
        this.modal.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'xl', scrollable: true }).result.then((result) => {
            this.closeResult = 'Closed with: ' + result;
        }, (reason) => {
            this.closeResult = 'Dismissed ' + this.getDismissedReason(reason);
        });
    }

    private getDismissedReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return 'with ' + reason;
        }
    }

    markerClicked(message: string): void {
        alert(message);
    }
}
