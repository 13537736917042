<header class="p-3">
    <h1 class="bg-white p-4 text-center rounded-lg shadow">Generate an Individual profile</h1>
</header>

<div class="container">
    <nav class="bg-white p-3 m-3 rounded-lg shadow d-flex justify-content-between">
        <app-back></app-back>
        <app-logout></app-logout>
    </nav>
    <div class="p-3 m-3 bg-white rounded-lg shadow">
        <div class="row">
            <div class="col submit">
                <div class="form-group m-3">
                    <!-- <div ngbDropdown class="d-block">
                        <h4><label for="request-depth">Request depth</label></h4>
                        <button class="btn btn-outline-primary" id="request-depth" ngbDropdownToggle>{{profileButtonText}}</button>
                        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                            <button *ngFor="let depth of requestDepth" (click)="setDepth(depth.name)" ngbDropdownItem>Profile: {{depth.name}}</button>
                        </div>
                    </div> -->
                    <!-- <div ngbDropdown class="d-block mt-5">
                        <h4><label for="request-format">Request format</label></h4>
                        <button class="btn btn-outline-primary" id="request-format" ngbDropdownToggle>{{formatButtonText}}</button>
                        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                            <button *ngFor="let format of requestFormat" (click)="setFormat(format.name)" ngbDropdownItem>{{format.name}}</button>
                        </div>
                    </div> -->
                    <div class="d-block mt-5">
                        <h4>Request Details</h4>
                        <div class="input-group d-block">
                            <label id="id-passport-label" class="d-block" for="id-input">ID or passport number</label>
                            <input type="text" [(ngModel)]="idPassport" class="form-control d-block w-100" id="id-input" aria-describedby="id-passport-label" placeholder="Please enter ID or passport number">
                        </div>
                        <!-- <div class="input-group d-block">
                            <label id="telephone-label" class="d-block mt-3" for="telephone-input">Telephone number</label>
                            <input type="text" [(ngModel)]="telephone" class="form-control d-block w-100" id="telephone-input" aria-describedby="telephone-label" placeholder="Please enter a telephone number">
                        </div> -->
                    </div>
                    <button type="submit" class="btn btn-primary mt-5 btn-lg" (click)="submitForm()">Submit</button>
                </div>
            </div>
            <div class="col report-details mr-3 p-3">
                <h4>Report details</h4>
                <div *ngIf="depthSelected" [innerHTML]="reportDescription" class="m-3"></div>
            </div>
        </div>
    </div>
</div>