import { Component, OnInit, TemplateRef } from '@angular/core';
import { ToastService } from 'src/app/services/toast.service';

@Component({
    selector: 'app-toast',
    templateUrl: './toast.component.html',
    styleUrls: ['./toast.component.css']
})
export class ToastComponent implements OnInit {
    toasts: Array<any> = this.toastService.toasts;

    constructor(public toastService: ToastService) { }

    ngOnInit(): void {
    }

    isTemplate(toast: any) {
        return toast.textOrTpl instanceof TemplateRef;
    }

    remove(toast: any) {
        this.toastService.remove(toast);
    }

}
